import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import {
  catalogItems,
  catalogThunks,
  selectedItemSelector,
  setSelectedItem,
} from "@redux/webshops/catalog/catalogSlice";
import { segmentsSelector } from "@redux/webshops/associations/associationsSlice";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { fuzzyFilter } from "@utils/table";

import { ConfirmationModal } from "@common/modals/confirmation-modal";
import { getAvailabilityWindowLabel, pricingTiersLabel, getSegmentsLabel, getSegmentsCell } from "@components/catalog";
import { formatToMonthDay } from "@src/utils/time";

import { ReactComponent as ChevronLeft } from "src/assets/img/icons/common/chevron-left.svg";
import { ReactComponent as ChevronRight } from "src/assets/img/icons/common/chevron-right.svg";

import PropTypes from "prop-types";

const columnHelper = createColumnHelper();

export function ItemsList({ openDrawer, tiers, globalFilter = "" }) {
  const dispatch = useDispatch();
  const { isOpen: isModalOpen, onClose: onCloseModal, onOpen: onModalOpen } = useDisclosure();

  const selectedItem = useSelector(selectedItemSelector);
  const items = useSelector(catalogItems);
  const segments = useSelector(segmentsSelector);

  const [sorting, setSorting] = useState([]);
  const [keepEndDate, setKeepEndDate] = useState(false);

  function handleSelectedItem(item) {
    if (window.getSelection().toString()) {
      // Open the drawer only if there is no text selected
      return;
    }
    dispatch(setSelectedItem(item));
    openDrawer();
  }

  async function handleUpdateAvailabilityWindow() {
    if (selectedItem) {
      const available = !selectedItem.available;
      dispatch(
        catalogThunks.updateItem({
          ...selectedItem,
          categoryId: selectedItem.category?.id,
          available,
          startTime: available ? new Date().toISOString() : null,
          endTime: keepEndDate ? selectedItem.endTime : null,
        }),
      );
      setKeepEndDate(false);

      onCloseModal();
    }
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: () => "Name",
        cell: (info) => (
          <HStack>
            <Image borderRadius={"3px"} w="32px" h="32px" src={info.row.original.imageUrl} />
            <Text>{info.getValue()}</Text>,
          </HStack>
        ),
      }),
      columnHelper.accessor((row) => pricingTiersLabel(tiers[row.pricingTierLevelId]), {
        id: "pricingTierLevelId",
        header: () => "Price",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.category?.name, {
        id: "category",
        header: () => "Category",
        cell: (info) => info.getValue() ?? "No category",
      }),
      columnHelper.accessor((row) => getSegmentsLabel(row.playerSegmentIds, segments), {
        id: "playerSegmentIds",
        header: () => "Segments",
        cell: (info) => getSegmentsCell(info.row.original.playerSegmentIds, segments),
      }),
      columnHelper.accessor("available", {
        id: "available",
        header: () => "Status",
        cell: function Cell(info) {
          const [isPending, setIsPending] = useState(false);
          const item = info.row.original;
          const byPassModal = !item.startTime && !item.endTime;

          async function handleOnStatusChange() {
            dispatch(setSelectedItem(item));
            if (byPassModal) {
              setIsPending(true);
              try {
                await dispatch(
                  catalogThunks.updateItem({
                    ...item,
                    categoryId: item.category?.id,
                    available: !item.available,
                    startTime: new Date().toISOString(),
                  }),
                ).unwrap();
                setIsPending(false);
              } catch (e) {
                setIsPending(false);
              }
              return;
            }
            onModalOpen();
          }

          return (
            <FormLabel
              minW={"124px"}
              m={0}
              display={"flex"}
              gap={"16px"}
              alignItems={"center"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Switch isChecked={info.getValue()} onChange={handleOnStatusChange} disabled={isPending} size={"sm"} />
              {info.getValue() ? "Available" : "Unavailable"}
            </FormLabel>
          );
        },
      }),
      columnHelper.accessor((row) => getAvailabilityWindowLabel(row.startTime, row.endTime, { timeZone: "UTC" }), {
        id: "availabilityWindow",
        header: () => "Availability window",
        cell: (info) => {
          const startTime = info.row.original?.startTime ?? null;
          const endTime = info.row.original?.endTime ?? null;

          if (!startTime && !endTime) {
            return (
              <Tooltip
                closeOnClick={false}
                label={
                  <Text size={"small"} fontWeight={500} color={"textWhite.400"}>
                    Availability window is not set.
                  </Text>
                }
              >
                <Text size="small" color={"textWhite.400"}>
                  --
                </Text>
              </Tooltip>
            );
          }

          return (
            <Tooltip
              size={"maxContent"}
              label={
                <Text size={"small"} fontWeight={500} color={"textWhite.400"}>
                  {getAvailabilityWindowLabel(startTime, endTime, {
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    timeZone: "UTC",
                  })}
                  . <Text as={"span"}>(UTC)</Text>
                </Text>
              }
            >
              <Text size="small" color={"textWhite.400"}>
                {getAvailabilityWindowLabel(startTime, endTime, { timeZone: "UTC" })}
              </Text>
            </Tooltip>
          );
        },
      }),
    ],
    [tiers, segments, dispatch, onModalOpen],
  );

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: { sorting, globalFilter },
    onSortingChange: setSorting,
    sortDescFirst: false,
    enableSortingRemoval: false,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: "fuzzy",
  });

  return (
    <>
      <TableContainer
        mt={"16px"}
        h={"675px"}
        border={"1px solid"}
        borderColor={"dark.600"}
        borderRadius={"12px"}
        position={"relative"}
      >
        {/* Scrollable until pagination implementation */}
        <Box maxH={"627px"} overflowY={"auto"}>
          <Table variant={"itemListCatalog"}>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      cursor={header.column.getCanSort() ? "pointer" : ""}
                    >
                      <HStack>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getIsSorted() === "asc" ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      </HStack>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id} onClick={() => handleSelectedItem(row.original)}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Flex
          position={"absolute"}
          bottom={0}
          margin={0}
          w={"100%"}
          bg={"dark.600"}
          padding={"12px 24px"}
          borderTop={"1px solid"}
          borderColor={"dark.400"}
        >
          <HStack ml="auto" gap={"8px"}>
            <Text>1/1</Text>
            <HStack gap={"8px"}>
              <IconButton icon={<Icon as={ChevronLeft} />} variant={"unstyled"} size={"xs"} />
              <IconButton icon={<Icon as={ChevronRight} />} variant={"unstyled"} size={"xs"} />
            </HStack>
          </HStack>
        </Flex>
      </TableContainer>
      <ConfirmationModal
        size={"fitContent"}
        isOpen={isModalOpen}
        onClose={() => {
          dispatch(setSelectedItem(null));
          onCloseModal();
        }}
        onAccept={handleUpdateAvailabilityWindow}
        title={selectedItem?.available ? "Make item unavailable?" : "Make item available?"}
        acceptText={"Yes, continue"}
        cancelText={"Cancel"}
      >
        {selectedItem ? getConfirmationModalContent({ item: selectedItem, setKeepEndDate }) : null}
      </ConfirmationModal>
    </>
  );
}

function getConfirmationModalContent({ item, setKeepEndDate }) {
  const startTime = item.startTime && new Date(item.startTime + "Z");
  const formattedStartTime = startTime
    ? formatToMonthDay(startTime, {
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      })
    : "Today";
  const endTime = item.endTime && new Date(item.endTime + "Z");
  const formattedEndTime = endTime
    ? formatToMonthDay(endTime, {
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      })
    : "Continuous";
  const today = new Date();

  const showCheckbox = today < startTime && endTime;
  return (
    <Box>
      <Text size="smallAlt" color="textDefault.400" lineHeight="20px">
        This item has a set availability window of{" "}
        <Text
          fontWeight={500}
          color={"textWhite.400"}
          as="span"
          noOfLines={2}
          size="smallAlt"
        >{`${formattedStartTime} - ${formattedEndTime}.(UTC)`}</Text>
        Do you wish to override this availability window?
      </Text>
      {showCheckbox ? (
        <Checkbox mt="32px" onChange={(e) => setKeepEndDate(e.target.checked)}>
          <Text fontWeight={600} size={"smallAlt"}>
            Keep end date: {formattedEndTime}.
          </Text>
        </Checkbox>
      ) : null}
    </Box>
  );
}

ItemsList.propTypes = {
  openDrawer: PropTypes.func.isRequired,
  tiers: PropTypes.object,
  globalFilter: PropTypes.string,
};
