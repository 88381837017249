import React from "react";
import { HStack } from "@chakra-ui/react";
import { LinkIcon } from "@chakra-ui/icons";

import { BaseSection, SectionText, SectionIcon } from "@components/builder/SidebarTabs";

export const DeepLinkSidebar = ({ selected = false, onClick = () => {} }) => {
  return (
    <BaseSection
      selected={selected}
      style={{
        borderRadius: "8px",
      }}
    >
      <HStack padding="16px" gap="8px" onClick={onClick}>
        <SectionIcon icon={LinkIcon} selected={selected} />
        <SectionText selected={selected}>Deep Link</SectionText>
      </HStack>
    </BaseSection>
  );
};
