import React, { useState, useRef } from "react";
import { Box, Icon, Input, InputGroup, InputRightElement, useOutsideClick } from "@chakra-ui/react";
import { DropdownContent, DropdownItem } from "@common/dropdown/Dropdown";
import isEqual from "lodash/isEqual";

import { ReactComponent as AccordionArrow } from "@assets/img/icons/common/accordion-arrow.svg";

import PropTypes from "prop-types";

export const DropdownOption = ({ name = "", options, itemSelected, onChange, isDisabled = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelectedItem = (item) => {
    onChange(item.value);
    setIsDropdownOpen(false);
  };

  useOutsideClick({
    ref: dropdownRef,
    handler: () => {
      setIsDropdownOpen(false);
    },
  });
  return (
    <InputGroup zIndex={isDropdownOpen ? 2 : "auto"}>
      <Box ref={dropdownRef} pos={"relative"} width={"100%"}>
        <Input
          value={itemSelected.label}
          p={"12px 16px"}
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          _focus={{ backgroundColor: "transparent", border: "2px solid", borderColor: "brandRed.400" }}
          _hover={{ p: "12px 16px" }}
          isReadOnly
          isDisabled={isDisabled}
          data-testid={`dropdown-option-input-${name}`}
        />

        <DropdownContent
          isOpen={isDropdownOpen}
          handleSelectedItem={handleSelectedItem}
          data-testid={`dropdown-option-${name}`}
        >
          {options.map((option) => (
            <DropdownItem
              key={option.id}
              value={option}
              onClick={(event) => {
                event.preventDefault();
                handleSelectedItem(option);
              }}
              getOptionLabel={(option) => option.label}
              selected={isEqual(itemSelected, option)}
            />
          ))}
        </DropdownContent>
        <InputRightElement pointerEvents={"none"}>
          <Icon
            as={AccordionArrow}
            width={"16px"}
            height={"16px"}
            fill={"textWhite.400"}
            transform={isDropdownOpen ? "rotate(0)" : "rotate(180deg)"}
            transition={"transform 0.2s ease"}
          />
        </InputRightElement>
      </Box>
    </InputGroup>
  );
};

DropdownOption.propTypes = {
  options: PropTypes.array.isRequired,
  itemSelected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
};
