import React from "react";
import { Box, FormControl, Text, VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useFormContext, Controller } from "react-hook-form";
import { SubsectionTitle, ItemSelector } from "@components/catalog";

import { catalogSelector } from "@redux/webshops/catalog/catalogSlice";
import isEmpty from "lodash/isEmpty";

export const ItemsList = () => {
  const { catalog } = useSelector(catalogSelector);
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const { items } = watch();

  return (
    <>
      <FormControl>
        <VStack alignItems={"flex-start"} gap={"24px"}>
          <VStack alignItems={"flex-start"} gap={"4px"}>
            <SubsectionTitle isRequired>Add items</SubsectionTitle>
            <Text size={"small"} color={"textSubtext.400"}>
              Add up to 52 items to a series. To avoid duplication, items in a series will not be available for any
              other section of your shop
            </Text>
          </VStack>
          <Controller
            name="items"
            control={control}
            rules={{
              required: "Please select at least 1 item",
            }}
            render={({ field }) => {
              return (
                <ItemSelector
                  catalog={catalog}
                  multiSelect={true}
                  showFilter={true}
                  selected={field.value}
                  onSelect={(items) => {
                    field.onChange([...items]);
                  }}
                  buttonLabel={"Add/Edit items"}
                  description={
                    "Select as many items as you wish to add to this series. An item can only be in 1 series at a time, its status must be Active. If you dont see an item then check its individual settings."
                  }
                />
              );
            }}
          />
        </VStack>
      </FormControl>

      {errors.items && (
        <Box w={"full"} as={"span"} alignItems={"start"} mt={"-15px"}>
          <Text size={"xs"} color={"brandRed.400"}>
            {errors.items.message}
          </Text>
        </Box>
      )}

      {!isEmpty(items) && (
        <VStack alignItems={"flex-start"} gap={"12px"}>
          <Text size={"small"} color={"textWHite.400"} fontWeight={600}>
            Items in this series
          </Text>
          <VStack
            w={"100%"}
            maxH={"350px"}
            alignItems={"flex-start"}
            gap={"4px"}
            overflowY={"auto"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#FFF",
                borderRadius: "24px",
              },
            }}
          >
            {items.map((item) => (
              <Text key={item.id} size={"small"} ml={"8px"}>
                {item.name}
              </Text>
            ))}
          </VStack>
        </VStack>
      )}
    </>
  );
};
