import { Flex, HStack, Text } from "@chakra-ui/react";

import PropTypes from "prop-types";

/**
 * @prop {boolean} isAvailable - if available
 * @prop {boolean} status - option value
 * @prop {function} onChange - callback function to change forms status.
 * @prop {node} children - children to render.
 */
const StatusOption = ({ isAvailable, status, children, onChange }) => (
  <Flex
    p="8px"
    borderRadius="8px"
    justifyContent="center"
    bgColor={isAvailable === status ? "dark.300" : ""}
    flex="1 0 0"
    cursor="pointer"
    onClick={() => {
      onChange(status);
    }}
    data-test-id={`status-option-${status ? "available" : "unavailable"}`}
  >
    <Text size="small" color={isAvailable === status ? "textWhite.400" : "textSubtext.400"}>
      {children}
    </Text>
  </Flex>
);

StatusOption.propTypes = {
  children: PropTypes.node.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  status: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * @prop {boolean} value - item value.
 * @prop {function} onChange - callback function to change status.
 */
export const ItemStatusSelector = ({ isAvailable, onChange }) => {
  return (
    <HStack w={"full"} p={"4px"} gap={"2px"} alignItems={"center"} borderRadius={"10px"} bgColor={"dark.500"}>
      <StatusOption isAvailable={isAvailable} status={true} onChange={onChange}>
        {"Available"}
      </StatusOption>
      <StatusOption isAvailable={isAvailable} status={false} onChange={onChange}>
        {"Unavailable"}
      </StatusOption>
    </HStack>
  );
};

ItemStatusSelector.propTypes = {
  isAvailable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
