import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Input, Link, Text, VStack } from "@chakra-ui/react";

import { sectionsThunks, storefrontSectionsSelector, updateDeepLink } from "@redux/webshops/builder/sectionsSlice";
import { DEFAULT_DEBOUNCE_TIME } from "@utils/consts";

import {
  COLOR_TYPES,
  FILE_TYPES,
  PropertiesHeader,
  SectionWrapper,
  SectionWrapperTooltip,
  getImage,
  getSectionTheme,
  generateFilePayloads,
  generateFileRemovePayloads,
  generateFileUpdatePayloads,
  generateThemePayloads,
  generateThemeResetPayloads,
} from "@utils/webshop/section";
import { StylePicker } from "../../StylePicker";

import { generatePayloads } from "./utils";

import { useAutoSave } from "@src/utils/hooks/useAutoSave";

import PropTypes from "prop-types";

export const DeepLinkPropertiesContainer = ({ webshop = {}, storefront = {} }) => {
  const dispatch = useDispatch();

  const data = useSelector(storefrontSectionsSelector).deepLink;
  const theme = getSectionTheme(storefront, data);

  const updateDeepLinkSection = useAutoSave(
    (payload) => dispatch(sectionsThunks.updateSection(payload)),
    DEFAULT_DEBOUNCE_TIME,
  );

  const updateDeepLinkTheme = useAutoSave(
    (payload) => dispatch(sectionsThunks.createSectionThemeOverride(payload)),
    DEFAULT_DEBOUNCE_TIME,
  );

  const onChange = (updates) => {
    const { response, request } = generatePayloads({
      data,
      updates,
    });

    updateDeepLinkSection({
      data: request,
      webshopId: webshop.id,
      sectionId: data.id,
    });
    dispatch(updateDeepLink(response));
  };

  const onThemeChange = (themeObj) => {
    const { response, request } = generateThemePayloads({
      data,
      themeObj,
    });
    updateDeepLinkTheme({
      data: request,
      webshopId: webshop.id,
      sectionId: data.id,
    });
    dispatch(updateDeepLink(response));
  };

  const onThemeReset = () => {
    const payloads = generateThemeResetPayloads({
      data,
    });
    dispatch(
      sectionsThunks.deleteSectionThemeOverride({
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateDeepLink(payloads.response));
  };

  const onFileCreate = async (fileObj) => {
    const payloads = await generateFilePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.createSectionFile({
        data: payloads.request,
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateDeepLink(payloads.response));
  };

  const onFileUpdate = (fileObj) => {
    const payloads = generateFileUpdatePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.updateSectionFile({
        data: payloads.request,
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateDeepLink(payloads.response));
  };

  const onFileRemove = (fileObj) => {
    const payloads = generateFileRemovePayloads({
      data,
      fileObj,
    });
    dispatch(
      sectionsThunks.deleteSectionFile({
        data: payloads.request,
        webshopId: webshop.id,
        sectionId: data.id,
      }),
    );
    dispatch(updateDeepLink(payloads.response));
  };

  useEffect(() => {
    // Flush all debounced updates on unmount
    return () => {
      updateDeepLinkSection.flush();
      updateDeepLinkTheme.flush();
    };
  }, [updateDeepLinkSection, updateDeepLinkTheme]);

  return (
    <DeepLinkProperties
      data={data}
      theme={theme}
      onChange={onChange}
      onThemeChange={onThemeChange}
      onThemeReset={onThemeReset}
      onFileCreate={onFileCreate}
      onFileUpdate={onFileUpdate}
      onFileRemove={onFileRemove}
    />
  );
};

export const DeepLinkProperties = ({
  data = {},
  theme = {},
  onChange = () => {},
  onThemeChange = () => {},
  onThemeReset = () => {},
  onFileCreate = () => {},
  onFileUpdate = () => {},
  onFileRemove = () => {},
}) => {
  const banner_image = getImage(FILE_TYPES.MAIN, data?.files);
  const [headline, setHeadline] = useState(data?.headline ?? "");
  const [description, setDescription] = useState(data?.description ?? "");

  useEffect(() => {
    setHeadline(data?.headline ?? "");
    setDescription(data?.description ?? "");
  }, [data?.description, data?.headline]);

  return (
    <Box>
      <PropertiesHeader />
      <SectionWrapper title="Styles" onReset={onThemeReset}>
        <StylePicker
          target="deep_link_theme_secondary"
          title="Text Color"
          initialColor={theme.secondary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.SECONDARY,
            });
          }}
        />
        <StylePicker
          target="deep_link_theme_primary"
          title="Button"
          presets={theme.presets}
          initialColor={theme.primary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.PRIMARY,
            });
          }}
        />
        <StylePicker
          target="deep_link_theme_background"
          title="Background Color"
          presets={theme.presets}
          initialColor={theme.background}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.BACKGROUND,
            });
          }}
        />
      </SectionWrapper>

      <SectionWrapper title={"Image"}>
        <StylePicker
          imageOnly
          noOverlay
          target="deep_link_header_banner_theme_primary"
          title="Banner Image"
          initialImage={banner_image.url}
          onImageChange={(file) => {
            onFileCreate({
              ...file,
              target: FILE_TYPES.MAIN,
            });
          }}
          onImageUpdate={(file) => {
            onFileUpdate({
              ...file,
              target: FILE_TYPES.MAIN,
            });
          }}
          onImageRemove={() => {
            onFileRemove({
              target: FILE_TYPES.MAIN,
            });
          }}
        />

        <StylePicker
          target="deep_link_header_theme_background"
          title="Image Background Color"
          presets={theme.presets}
          initialColor={data?.bannerImageBackgroundColor ?? "#FFFFFF"}
          onColorChange={(colorObj) => {
            onChange({
              bannerImageBackgroundColor: colorObj.value,
            });
          }}
        />
      </SectionWrapper>

      <SectionWrapper
        title={"Headline"}
        icon={
          <SectionWrapperTooltip>
            <VStack alignItems={"start"} gap={"10px"}>
              <Text>This headline will be used for all deep link modals.</Text>

              <Text>
                Visit{" "}
                <Link
                  color={"textWhite.400"}
                  _hover={{ textDecoration: "underline", color: "textWhite.400" }}
                  href="https://developer.sanlo.io/docs/monetize-web-shop"
                  isExternal
                >
                  Monetize Webshop
                </Link>{" "}
                for tips.
              </Text>
            </VStack>
          </SectionWrapperTooltip>
        }
      >
        <Box px="12px" pt="8px" mb="12px">
          <Input
            borderRadius={"10px"}
            value={headline}
            placeholder="Add a headline"
            onChange={(event) => {
              onChange({
                headline: event.target.value,
              });
              setHeadline(event.target.value);
            }}
          />
        </Box>
      </SectionWrapper>

      <SectionWrapper
        title={"Description"}
        icon={
          <SectionWrapperTooltip>
            <VStack alignItems={"start"} gap={"10px"}>
              <Text>This description will be used for all deep link modals.</Text>

              <Text>
                Visit{" "}
                <Link
                  color={"textWhite.400"}
                  _hover={{ textDecoration: "underline", color: "textWhite.400" }}
                  href="https://developer.sanlo.io/docs/monetize-web-shop"
                  isExternal
                >
                  Monetize Webshop
                </Link>{" "}
                for tips.
              </Text>
            </VStack>
          </SectionWrapperTooltip>
        }
      >
        <Box px="12px" pt="8px" mb="12px">
          <Input
            borderRadius={"10px"}
            value={description}
            placeholder="Add a description"
            onChange={(event) => {
              onChange({
                description: event.target.value,
              });
              setDescription(event.target.value);
            }}
          />
        </Box>
      </SectionWrapper>

      <SectionWrapper title="Deep Link URL">
        <Box px="12px" pt="8px" mb="12px">
          <Text size={"small"} color="textSubtext.400">
            Visit the Catalog and within each item’s settings, find and edit that item’s deep link url.
          </Text>
        </Box>
      </SectionWrapper>
    </Box>
  );
};

DeepLinkProperties.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
  onChange: PropTypes.func,
  onThemeChange: PropTypes.func,
  onThemeReset: PropTypes.func,
};

DeepLinkPropertiesContainer.propTypes = {
  webshop: PropTypes.object,
  storefront: PropTypes.object,
};
