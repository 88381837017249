import axios from "axios";
import { getCookie } from "@utils/general";

export const submitHubspotForm = async ({ email, financingAmount, financingPeriod, notes }) => {
  const portalId = "21209223";
  const formGuid = "daeb4d1a-9cf7-42f3-a810-6f00ee07c856";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      context: {
        hutk: getCookie("hubspotutk"),
        pageUri: "https://app.sanlo.io/growth-capital",
        pageName: "Sanlo",
      },
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "desired_financing_amount",
          value: financingAmount,
        },
        {
          name: "financing_period",
          value: financingPeriod,
        },
        {
          name: "notes",
          value: notes,
        },
      ],
    },
    config,
  );
  return response;
};
