import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Input,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { fuzzyFilter } from "@utils/table";

import { pricingTiersLabel } from "../utils";
import isEmpty from "lodash/isEmpty";

import PropTypes from "prop-types";

const columnHelper = createColumnHelper();

export const AddStep = ({
  items = [],
  selectedItem = [],
  priceTiersMap = {},
  description = "",
  multiSelect = false,
  showFilter = false,
  setSelectedItem = () => {},
  onSelect = () => {},
  handleNextStep = () => {},
  onClose = () => {},
}) => {
  const [filterValue, setFilterValue] = useState("");
  const [sorting, setSorting] = useState([]);

  const handleItemClick = (clickedItem) => {
    if (!multiSelect) {
      onSelect(clickedItem);
      onClose();
      return;
    }

    setSelectedItem((prevItems) => {
      const isItemInList = prevItems.find((item) => item.id === clickedItem.id);
      if (isItemInList) {
        return prevItems.filter((item) => item.id !== clickedItem.id);
      } else {
        return [...prevItems, clickedItem];
      }
    });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: () => "Name",
        cell: (info) => (
          <HStack>
            <Image borderRadius={"3px"} w="32px" h="32px" src={info.row.original.imageUrl} />
            <Text>{info.getValue()}</Text>,
          </HStack>
        ),
      }),
      columnHelper.accessor((row) => row.category?.name, {
        id: "category",
        header: () => "Category",
        cell: (info) => info.getValue() ?? "-",
      }),
      columnHelper.accessor((row) => pricingTiersLabel(priceTiersMap[row.pricingTierLevelId]), {
        id: "pricingTierLevelId",
        header: () => "Price",
        cell: (info) => info.getValue(),
      }),
    ],
    [priceTiersMap],
  );

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: { sorting, globalFilter: filterValue },
    onSortingChange: setSorting,
    sortDescFirst: false,
    enableSortingRemoval: false,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: "fuzzy",
  });

  return (
    <VStack gap={"24px"} alignItems={"stretch"}>
      <VStack alignItems={"flex-start"} mt={"30px"}>
        <Heading size={"regular"} textColor={"textWhite.400"} fontWeight={600}>
          {`Select Item${multiSelect ? "s" : ""}`}
        </Heading>
        {showFilter && (
          <Input
            w={"280px"}
            aria-label={"filter"}
            placeholder={"Filter items by name or category"}
            onChange={(e) => setFilterValue(e.target.value)}
          />
        )}
        {!isEmpty(description) && (
          <Text aria-label={"description"} size={"small"} color={"textSubtext.400"}>
            {description}
          </Text>
        )}
      </VStack>
      <VStack gap={0}>
        <TableContainer w={"100%"} h={"510px"} position={"relative"}>
          <Box
            h={"510px"}
            overflowY={"auto"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#FFF",
                borderRadius: "24px",
              },
            }}
          >
            <Table variant={"addItemSeries"}>
              <Tbody>
                {table.getRowModel().rows.map((row) => {
                  let isItemInList;
                  if (multiSelect) {
                    isItemInList = selectedItem.find((itemList) => itemList.id === row.original.id);
                  } else {
                    isItemInList = selectedItem.id === row.original.id;
                  }

                  return (
                    <Tr
                      key={row.id}
                      onClick={() => handleItemClick(row.original)}
                      bg={isItemInList ? "rgba(130, 128, 255, 0.16)" : "dark.700"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </TableContainer>
      </VStack>
      <HStack justifyContent={"space-between"}>
        <Button h={"32px"} variant={"primary"} onClick={onClose}>
          Cancel
        </Button>
        {multiSelect && (
          <Button h={"32px"} aria-label={"add items"} isDisabled={isEmpty(selectedItem)} onClick={handleNextStep}>
            Add items
          </Button>
        )}
      </HStack>
    </VStack>
  );
};

AddStep.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  priceTiersMap: PropTypes.object,
  description: PropTypes.string,
  multiSelect: PropTypes.bool,
  showFilter: PropTypes.bool,
  setSelectedItem: PropTypes.func,
  onSelect: PropTypes.func,
  handleNextStep: PropTypes.func,
  onClose: PropTypes.func,
};
