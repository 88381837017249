import React from "react";
import classnames from "classnames";
import styled, { css } from "styled-components";

import { BodySmallPlusCSS } from "@styled";
import { Link } from "@components/common/link";

const SupportCSS = css`
  // Button reset css
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  ${BodySmallPlusCSS}
`;

export const StyledSupportButton = styled.button`
  ${SupportCSS}
`;

export const StyledSupportLink = styled(Link)`
  ${SupportCSS}
`;

const Support = ({
  className = "",
  style = {},
  email = false,
  text = "",
  red = false,
  bold = false,
  onClick = () => {},
}) => {
  const styling = {
    ...style,
    fontWeight: bold ? "bold" : "normal",
  };

  const classes = classnames(className, {
    "support-link-red": red,
  });

  const handleClick = () => {
    onClick();
    if (email) return;
    if (window?.HubSpotConversations?.widget) {
      window.HubSpotConversations.widget.open();
    } else {
      console.warn("No HubSpot widget found");
    }
  };

  if (email) {
    return (
      <StyledSupportLink href="mailto:support@sanlo.io" style={styling} className={classes} onClick={handleClick}>
        {text || "support@sanlo.io"}
      </StyledSupportLink>
    );
  }

  return (
    <StyledSupportButton style={styling} className={classes} onClick={handleClick}>
      {text || "Contact us"}
    </StyledSupportButton>
  );
};

export default Support;
