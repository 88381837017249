import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@chakra-ui/react";
import { debounce } from "lodash";

import {
  PropertiesHeader,
  SectionWrapper,
  getSectionTheme,
  COLOR_TYPES,
  generateFilePayloads,
  generateFileUpdatePayloads,
  generateFileRemovePayloads,
} from "@utils/webshop/section";
import { StylePicker } from "../../StylePicker";
import { BackgroundStylePicker } from "../BackgroundStylePicker";

import { builderThunks, updateStorefrontGlobal } from "@redux/webshops/builder/builderSlice";

import { generateThemePayloads, SECTION_TYPE } from "./utils";
import { DEFAULT_DEBOUNCE_TIME } from "@utils/consts";
import PropTypes from "prop-types";

export const GlobalPropertiesContainer = ({ webshop = {}, storefront = {} }) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const debouncedThemeUpdate = useCallback(
    debounce((data) => {
      dispatch(builderThunks.updateStorefrontTheme(data));
    }, DEFAULT_DEBOUNCE_TIME),
    [dispatch],
  );

  const onThemeUpdate = (themeObj) => {
    const payload = generateThemePayloads({
      storefront,
      updates: themeObj,
    });
    debouncedThemeUpdate({
      data: payload.request,
      webshopId: webshop.id,
    });
    dispatch(updateStorefrontGlobal(payload.response));
  };

  useEffect(() => {
    return () => {
      debouncedThemeUpdate.flush();
    };
    // eslint-disable-next-line
  }, []);

  const onFileCreate = async (fileObj) => {
    const payloads = await generateFilePayloads({
      data: {
        type: SECTION_TYPE,
        ...storefront,
      },
      fileObj,
    });
    dispatch(
      builderThunks.createFile({
        data: payloads.request,
        webshopId: webshop.id,
      }),
    );
    dispatch(updateStorefrontGlobal(payloads.response));
  };

  const onFileRemove = (fileObj) => {
    const payloads = generateFileRemovePayloads({
      data: storefront,
      fileObj,
    });
    dispatch(
      builderThunks.deleteFile({
        fileId: fileObj.id,
        webshopId: webshop.id,
      }),
    );
    dispatch(updateStorefrontGlobal(payloads.response));
  };

  return (
    <GlobalProperties
      storefront={storefront}
      onThemeChange={onThemeUpdate}
      onFileCreate={onFileCreate}
      onFileUpdate={(fileObj) => {
        const payloads = generateFileUpdatePayloads({
          data: {
            type: SECTION_TYPE,
            ...storefront,
          },
          fileObj,
        });
        dispatch(
          builderThunks.updateFile({
            data: payloads.request,
            webshopId: webshop.id,
          }),
        );
        dispatch(updateStorefrontGlobal(payloads.response));
      }}
      onFileRemove={onFileRemove}
    />
  );
};

GlobalPropertiesContainer.propTypes = {
  webshop: PropTypes.object,
  storefront: PropTypes.object,
};

export const GlobalProperties = ({
  storefront = {},
  onThemeChange = () => {},
  onFileCreate = () => {},
  onFileUpdate = () => {},
  onFileRemove = () => {},
}) => {
  const theme = getSectionTheme(storefront);

  /*
    Right now I think the golbal values are the "presets". Currently
    there is nothing higher order than global, and the theme on the webshop
    object is blank (bug?). Disabling presets for now cause otherwise its weird.
  */

  return (
    <Box>
      <PropertiesHeader />

      <SectionWrapper title="Styles">
        <StylePicker
          target="global_theme_primary"
          title="Primary"
          initialColor={theme.primary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.PRIMARY,
            });
          }}
        />
        <StylePicker
          target="global_theme_secondary"
          title="Secondary"
          initialColor={theme.secondary}
          onColorChange={(colorObj) => {
            onThemeChange({
              ...colorObj,
              targetColor: COLOR_TYPES.SECONDARY,
            });
          }}
        />
        <BackgroundStylePicker
          checkLegacyBg
          image
          target="global_theme_background"
          theme={theme}
          data={storefront}
          onThemeChange={onThemeChange}
          onFileCreate={onFileCreate}
          onFileUpdate={onFileUpdate}
          onFileRemove={onFileRemove}
        />
      </SectionWrapper>
    </Box>
  );
};

GlobalProperties.propTypes = {
  storefront: PropTypes.object,
  onFileCreate: PropTypes.func,
  onFileUpdate: PropTypes.func,
  onFileRemove: PropTypes.func,
  onThemeChange: PropTypes.func,
};
