import HTTP from "@redux/http";

const SANLO_WEB_API_URL = process.env.REACT_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/console/webshops`;

class CatalogService {
  getCatalogFromWebshop(webshopId) {
    return HTTP.req.get(`${BASE}/${webshopId}/catalog`);
  }

  createItem(data, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/catalog/item`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  updateItem(data, webshopId, itemId) {
    return HTTP.req.put(`${BASE}/${webshopId}/catalog/item/${itemId}`, data);
  }

  updateImage(data, webshopId, itemId) {
    return HTTP.req.put(`${BASE}/${webshopId}/catalog/item/${itemId}/image`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  deleteItem(itemId, webshopId) {
    return HTTP.req.delete(`${BASE}/${webshopId}/catalog/item/${itemId}`);
  }
  getPricingTiers() {
    return HTTP.req.get(`${BASE}/catalog/pricing/tiers`);
  }

  generateDeepLink(webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/deep-link/generate`);
  }

  verifyDeepLink(deepLinkCode, webshopId) {
    return HTTP.req.post(`${BASE}/${webshopId}/deep-link/check-availability`, { deepLinkCode });
  }
}

export default new CatalogService();
