import sessionMiddleware from "./session/middleware";
import earningsMiddleware from "./earnings/middleware";
import bankingMiddleware from "./banking/middleware";
import capitalOffersMiddleware from "./offers/middleware";
import changeMonthMiddleware from "./financials/middleware/changeMonth";

// Integrations
import baseMiddleware from "./integrations/base/middleware";
import adjustMiddleware from "./integrations/adjust/middleware";
import advancesMiddleware from "./integrations/advances/middleware";
import appsFlyerMiddleware from "./integrations/appsFlyer/middleware";
import appStoreMiddleware from "./integrations/appStore/middleware";
import googlePlayMiddleware from "./integrations/googlePlay/middleware";
import plaidMiddleware from "./integrations/plaid/middleware";

export default [
  sessionMiddleware,
  earningsMiddleware,
  bankingMiddleware,
  capitalOffersMiddleware,
  changeMonthMiddleware,

  baseMiddleware,
  adjustMiddleware,
  advancesMiddleware,
  appsFlyerMiddleware,
  appStoreMiddleware,
  googlePlayMiddleware,
  plaidMiddleware,
];
