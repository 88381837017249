export const getFormattedForm = (series = {}) => {
  const formatTime = (time) => (time ? new Date(time + "Z") : "");

  const {
    startTime,
    endTime,
    id = "",
    name = "",
    available = false,
    recurrenceInterval = null,
    endParameter = "LOOP",
    items = [],
  } = series;

  return {
    startTime: formatTime(startTime),
    endTime: formatTime(endTime),
    id,
    name,
    available,
    recurrenceInterval,
    endParameter,
    items,
  };
};
