import { Link } from "@chakra-ui/react";
import { CardText } from "@common/cards/blur-card-text";

const BANKING_PULLING_DATA = (
  <CardText
    maxW={"270px"}
    text={"Thanks for connecting your banks data. Your data will appear within 24 hours."}
    link={"Connect more banks"}
  />
);

const PLATFORM_PULLING_DATA = (
  <CardText
    maxW={"270px"}
    text={"Thanks for connecting your platforms data. Your data will appear within 24 hours."}
    link={"Connect more platforms"}
  />
);

const BANKING_BROKEN_STATE = (
  <CardText text={"Oops, it looks like your banks accounts have lost connection."} link={"Reconnect"} />
);

const PLATFORM_BROKEN_STATE = (
  <CardText text={"Oops, it looks like your platforms have lost connection."} link={"Reconnect"} />
);

export const getFinancialCardsBlurryMessage = ({ banking, platform }) => {
  const { isPullingData: isBankingPullingData, isBroken: isBankingBroken } = banking;
  const { isPullingData: isPlatformPullingData, isBroken: isPlatformBroken } = platform;

  const bankMessage = isBankingBroken ? (
    BANKING_BROKEN_STATE
  ) : isBankingPullingData ? (
    BANKING_PULLING_DATA
  ) : (
    <CardText
      text={"Monitor your bank balance, revenue, user acquisition spending, and monthly burn."}
      link={"Connect banks"}
    />
  );

  const upcomingRevenueMessage = isPlatformBroken ? (
    PLATFORM_BROKEN_STATE
  ) : isPlatformPullingData ? (
    PLATFORM_PULLING_DATA
  ) : (
    <CardText text={"Track your upcoming revenue payouts from platforms."} link={"Connect platforms"} />
  );
  const upcomingCashoutMessage = isPlatformBroken ? (
    PLATFORM_BROKEN_STATE
  ) : isPlatformPullingData ? (
    PLATFORM_PULLING_DATA
  ) : (
    <CardText text={"Track your upcoming cash outflows."} link={"Connect platforms"} />
  );

  return { bankMessage, upcomingRevenueMessage, upcomingCashoutMessage };
};

export const getNetBurnBlurryMessage = ({ isPullingData, isBroken }) => {
  return isBroken ? (
    BANKING_BROKEN_STATE
  ) : isPullingData ? (
    BANKING_PULLING_DATA
  ) : (
    <CardText text={"Track your monthly burn."} link={"Connect banks"} />
  );
};

export const getCashActivityBlurryMessage = ({ isPullingData, isBroken }) => {
  return isBroken ? (
    <CardText text={"Oops, it looks like your accounts have lost connection."} link={"Reconnect"} />
  ) : isPullingData ? (
    <CardText
      maxW={"270px"}
      text={"Thanks for connecting your accounts. Your data will appear within 24 hours."}
      link={"Connect more data sources"}
    />
  ) : (
    <CardText text={"Get insight into your upcoming cash activity."} link={"Connect data sources"} />
  );
};

export const getCashFlowBreakdownBlurryMessage = ({ isPullingData, isBroken }) => {
  return isBroken ? (
    BANKING_BROKEN_STATE
  ) : isPullingData ? (
    BANKING_PULLING_DATA
  ) : (
    <CardText text={"View details of you historic cash activity."} link={"Connect banks"} />
  );
};

export const getUpcomingPayoutsBlurryMessage = ({ isPullingData, isBroken }) => {
  return isBroken ? (
    <CardText maxW={"560px"} link={"Reconnect"}>
      Oops, it looks like your platforms have lost connection. Reconnect platforms and/or forward your invoices to{" "}
      <Link href="mailto:invoices@sanlo.io" textDecoration="underline">
        invoices@sanlo.io
      </Link>{" "}
      to get the full picture.
    </CardText>
  ) : isPullingData ? (
    PLATFORM_PULLING_DATA
  ) : (
    <CardText maxW={"560px"} link={"Connect platforms"}>
      See your upcoming platform and ad network revenue payouts up to 3 months in advance. Connect your platforms in
      minutes and/or forward your invoices to{" "}
      <Link href="mailto:invoices@sanlo.io" textDecoration="underline">
        invoices@sanlo.io
      </Link>{" "}
      to get the full picture.
    </CardText>
  );
};

export const getDailyRevenueBlurryMessage = ({ isPullingData, isBroken }) => {
  return isBroken ? (
    PLATFORM_BROKEN_STATE
  ) : isPullingData ? (
    PLATFORM_PULLING_DATA
  ) : (
    <CardText text={"Track your daily revenues from the platforms."} link={"Connect platforms"} />
  );
};
