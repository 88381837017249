import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Box, VStack } from "@chakra-ui/react";
import { associationsThunks } from "@redux/webshops/associations/associationsSlice";
import { builderThunks } from "@redux/webshops/builder/builderSlice";
import { catalogThunks } from "@redux/webshops/catalog/catalogSlice";
import { setSelectedWebshop, webshopsSelector, webshopsThunks } from "@redux/webshops/webshopsSlice";
import { Header } from "./components/header";

export function WebshopLayout() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const webshopsData = useSelector(webshopsSelector);
  const webshopsMap = webshopsData.webshopsMap;
  const selectedWebshop = webshopsData.selectedWebshop;

  useEffect(() => {
    if (!selectedWebshop) {
      dispatch(webshopsThunks.getWebshops());
    }
  }, [dispatch, selectedWebshop]);

  useEffect(() => {
    if (!id) return;
    if (webshopsMap[id]) {
      // If the webshopMap updates (the webshop call succeeds) and we still
      // haven't found the selected webshop, we set it here
      dispatch(setSelectedWebshop(webshopsMap[id]));
    }
  }, [webshopsMap, dispatch, id, selectedWebshop]);

  useEffect(() => {
    if (id) {
      dispatch(builderThunks.getStorefront(id));
      // The catalog is needed to drive the drop-downs for item selection
      // right now and to set the title if its not set
      dispatch(catalogThunks.getCatalogFromWebshop(id));
      // Associations are needed for category
      dispatch(associationsThunks.getAssociations(id));
    }
  }, [id, dispatch]);

  const containerStyles = {
    maxWidth: pathname.includes("/builder") ? "100%" : "1094px",
    margin: pathname.includes("/builder") ? "none" : "auto",
    px: pathname.includes("/builder") ? 0 : "32px",
    pt: pathname.includes("/builder") ? 0 : "24px",
  };
  return (
    <VStack alignItems={"stretch"} h="full" gap={0}>
      <Header />
      <Box flex={1} {...containerStyles} overflowY={"scroll"} w={"full"}>
        <Outlet />
      </Box>
    </VStack>
  );
}
