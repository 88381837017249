import React from "react";
import { HStack } from "@chakra-ui/react";

import { BaseSection, SectionText, SectionIcon } from "@components/builder/SidebarTabs";

import { ReactComponent as AlignBottom } from "@assets/img/icons/common/alignbottom.svg";

export const FooterSidebar = ({ selected = false, onClick = () => {} }) => {
  return (
    <BaseSection selected={selected}>
      <HStack padding="0.75rem" onClick={onClick}>
        <SectionIcon icon={AlignBottom} selected={selected} />
        <SectionText selected={selected}>Footer</SectionText>
      </HStack>
    </BaseSection>
  );
};
