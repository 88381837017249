import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, useDisclosure, Input, HStack } from "@chakra-ui/react";

import { AddItem } from "./components/add-item/AddItem";
import { ItemsList } from "./components/items-list/ItemsList";

import {
  catalogSelector,
  catalogThunks,
  setSelectedItem,
  catalogNewItemLoadingSelector,
} from "@redux/webshops/catalog/catalogSlice";

import { EmptyState } from "@components/catalog";
import ErrorBoundary from "@components/ErrorBoundary";

import { ReactComponent as PlusIcon } from "@assets/img/icons/common/plus.svg";
import isEmpty from "lodash/isEmpty";

export function Item() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [itemNameFilter, setItemNameFilter] = useState("");
  const dispatch = useDispatch();
  const { deepLinkCode, catalog } = useSelector(catalogSelector);
  const isNewItemLoading = useSelector(catalogNewItemLoadingSelector);

  const handleNewItem = () => {
    if (deepLinkCode) {
      onOpen();
    } else {
      dispatch(catalogThunks.generateDeepLink({ openNewItem: onOpen }));
    }
    dispatch(setSelectedItem(null));
  };
  return (
    <>
      <ErrorBoundary
        name="catalog-page-items-list"
        show={!isEmpty(catalog?.data?.tiers)}
        showAltUI={!catalog?.data?.items?.length}
        altUI={<EmptyState openItemDrawer={handleNewItem} />}
      >
        <HStack justifyContent={"space-between"}>
          <Button
            leftIcon={<Icon as={PlusIcon} w="16px" h="16px" color={"white"} />}
            onClick={handleNewItem}
            isLoading={isNewItemLoading}
          >
            New Item
          </Button>
          <Input maxW={"246px"} placeholder="Search item" onChange={(e) => setItemNameFilter(e.target.value)} />
        </HStack>
        <ItemsList openDrawer={onOpen} tiers={catalog?.data?.tiers} globalFilter={itemNameFilter} />
      </ErrorBoundary>
      <AddItem isOpen={isOpen} onClose={onClose} />
    </>
  );
}
