/*
  Document uploads quick summary

  1) /signedURL returns URL to upload file to, returns an "objectName"
  2) File is uploaded to GCS
  3) When completed /fileUploaded is called, sending along the "objectName"
  4) When completed, /finishUploading is called to tell the server the entire process is done

  Api documentation:
  https://app.dev.sanlo.io/api/swagger-ui/index.html?configUrl=/api/v3/api-docs/swagger-config#/document-controller
*/

import actions from "@redux/actions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";
import { sentryError } from "@utils/sentryError";

import {
  parseUploadError,
  clearFileFromState,
  checkForUploading,
  updatedParsedUploadedFiles,
  checkUseSignedURLComplete,
} from "./utils";
import UploadService from "./uploadApi";

const initialState = {
  data: {
    // Match up the APIs
    status: {},
    signedURL: {},
    useSignedURL: {},
    fileUploaded: {},
    // Custom
    fileList: [],
    uploadedDBFiles: [],
    verifyUseSigned: {},
    successfulFiles: {},
    errorFiles: {},
    uploadedFiles: {},
    isUploading: false,
    useSignedComplete: false,
  },
  requestStatus: {
    status: setStatusDefaults(),
    signedURL: {},
    useSignedURL: {},
    fileUploaded: {},
    finishedUploading: setStatusDefaults(),
  },
};

const errorMsg = "There was an issue trying to upload the file, please remove it and try again.";

export const uploadThunks = {
  getDocuments: createAsyncThunk("upload/getDocuments", async (type = "") => {
    const res = await UploadService.getDocuments(type);
    return res.data;
  }),

  signedURL: createAsyncThunk("upload/signedURL", async (data = {}) => {
    const res = await UploadService.signedURL(data);
    return res.data;
  }),

  useSignedURL: createAsyncThunk("upload/useSignedURL", async ({ url = "", data = {} }) => {
    await UploadService.useSignedURL(url, data);
    return;
  }),

  saveToSanloDB: createAsyncThunk("upload/saveToSanloDB", async (data = {}) => {
    const res = await UploadService.saveToSanloDB(data);
    return res.data;
  }),

  // After all the files are successful and any errors haven been dealt
  // with, tell Sanlo uploading is finished (noticiation to looks at documents)
  finishedUploading: createAsyncThunk("upload/finishedUploading", async (_, { getState, dispatch }) => {
    const state = getState();
    const canUpdateOnboardingProcess = state.session.onboarding.canUpdateOnboardingProcess;
    const res = await UploadService.finishedUploading();
    dispatch(actions.banking.status.request());
    dispatch(actions.integrations.advances.getSummary.request());
    if (canUpdateOnboardingProcess) {
      dispatch(actions.session.getOnboardingProgress.request());
      dispatch(actions.session.setCanUpdateOnboardingProcess(false));
    }
    return res.data;
  }),
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setFileList: (state, action) => {
      const files = action.payload;
      state.data.fileList = files;
      state.data.useSignedComplete = false;
      files.forEach((file) => {
        state.data.verifyUseSigned[file] = { complete: false };
      });
    },
    clearFileData: (state, action) => {
      clearFileFromState(state, action.payload);
    },
    clearAllFileData: (state) => {
      const filesUploadedKeys = Object.keys(state.data.fileUploaded);
      filesUploadedKeys.forEach((fileName) => {
        state = clearFileFromState(state, fileName);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // getDocuments
      .addCase(uploadThunks.getDocuments.pending, (state) => {
        state.requestStatus.status = getRequestStatus();
      })
      .addCase(uploadThunks.getDocuments.fulfilled, (state, action) => {
        state.requestStatus.status = getResponseStatus();
        state.data.uploadedDBFiles = action.payload;
        state.data.uploadedFiles = updatedParsedUploadedFiles(action.payload);
      })
      .addCase(uploadThunks.getDocuments.rejected, (state) => {
        state.requestStatus.status = getErrorStatus();
      })

      // signedURL
      .addCase(uploadThunks.signedURL.pending, (state, action) => {
        state.data.isUploading = true;
        const files = action.meta.arg.files;
        files.forEach((file) => {
          const fileName = file.fileName;
          // Clear out existing file data if it exists
          clearFileFromState(state, fileName);
          state.requestStatus.signedURL[fileName] = getRequestStatus();
        });
      })
      .addCase(uploadThunks.signedURL.fulfilled, (state, action) => {
        const files = action.payload;
        files.forEach((file) => {
          const fileName = file.fileName;
          if (file.succeeded) {
            state.requestStatus.signedURL[fileName] = getResponseStatus();
            state.data.signedURL[fileName] = file;
          } else {
            state.requestStatus.signedURL[fileName] = getErrorStatus();
            state.data.signedURL[fileName] = { error: true };
            state.data.errorFiles[fileName] = errorMsg;
            state.data.isUploading = checkForUploading(state);
          }
        });
      })
      .addCase(uploadThunks.signedURL.rejected, (state, action) => {
        const files = action.meta.arg.files;
        files.forEach((file) => {
          const fileName = file.fileName;
          const parsedError = parseUploadError(action.error);
          state.requestStatus.signedURL[fileName] = getErrorStatus();
          state.data.signedURL[fileName] = { error: true };
          state.data.errorFiles[fileName] = parsedError.message || errorMsg;
          state.data.isUploading = checkForUploading(state);
        });
      })

      // useSignedURL
      .addCase(uploadThunks.useSignedURL.pending, (state, action) => {
        const fileName = action.meta.arg.data.fileName;
        state.requestStatus.useSignedURL[fileName] = getRequestStatus();
      })
      .addCase(uploadThunks.useSignedURL.fulfilled, (state, action) => {
        const fileName = action.meta.arg.data.fileName;
        state.requestStatus.useSignedURL[fileName] = getResponseStatus();
        state.data.useSignedURL[fileName] = { error: false };
        state.data.verifyUseSigned[fileName] = { complete: true };
        const isUseSignedComplete = checkUseSignedURLComplete(state);
        if (isUseSignedComplete) {
          state.data.useSignedComplete = true;
          state.data.verifyUseSigned = {};
        }
      })
      .addCase(uploadThunks.useSignedURL.rejected, (state, action) => {
        const { fileName, contentType } = action.meta.arg.data;
        const { url } = action.meta.arg;

        // Notify Sentry that Google Cloud Service fails
        sentryError(new Error(`GCS upload error - ${action.error.message}`), { fileName, contentType, url });

        state.requestStatus.useSignedURL[fileName] = getErrorStatus();
        state.data.useSignedURL[fileName] = { error: true };
        state.data.errorFiles[fileName] = errorMsg;
        state.data.isUploading = checkForUploading(state);
      })

      // saveToSanloDB
      .addCase(uploadThunks.saveToSanloDB.pending, (state, action) => {
        const files = action.meta.arg.data;
        files.forEach((file) => {
          const fileName = file.fileName;
          state.requestStatus.fileUploaded[fileName] = getRequestStatus();
        });
      })
      .addCase(uploadThunks.saveToSanloDB.fulfilled, (state, action) => {
        const files = action.payload;
        files.forEach((file) => {
          const fileName = file.fileName;
          if (file.succeeded) {
            state.requestStatus.fileUploaded[fileName] = getResponseStatus();
            state.data.fileUploaded[fileName] = {
              success: true,
              error: false,
            };
            state.data.successfulFiles = true;
            // Clear out any error the file may have had
            delete state.data.errorFiles[fileName];
          } else {
            state.requestStatus.fileUploaded[fileName] = getErrorStatus();
            state.data.fileUploaded[fileName] = {
              success: false,
              error: true,
            };
            state.data.errorFiles[fileName] = errorMsg;
          }
        });
        state.data.isUploading = checkForUploading(state);
        state.data.useSignedComplete = false;
      })
      .addCase(uploadThunks.saveToSanloDB.rejected, (state, action) => {
        const files = action.meta.arg.data;
        files.forEach((file) => {
          const fileName = file.fileName;
          const parsedError = parseUploadError(action.error);
          state.requestStatus.fileUploaded[fileName] = getErrorStatus();
          state.data.fileUploaded[fileName] = {
            ...parsedError, // We'll overwrite things like "Bad Request" but that's fine
            success: false,
            error: true,
          };
          state.data.errorFiles[fileName] = parsedError.message || errorMsg;
        });
        state.data.isUploading = checkForUploading(state);
      })

      // finishedUploading
      .addCase(uploadThunks.finishedUploading.pending, (state) => {
        state.requestStatus.finishedUploading = getRequestStatus();
      })
      .addCase(uploadThunks.finishedUploading.fulfilled, (state) => {
        state.requestStatus.finishedUploading = getResponseStatus();
        state.data.isUploading = checkForUploading(state);
      })
      .addCase(uploadThunks.finishedUploading.rejected, (state) => {
        state.requestStatus.finishedUploading = getErrorStatus();
        state.data.isUploading = checkForUploading(state);
      });
  },
});

export const uploadSelector = (state) => state.upload;

export const { setFileList, clearFileData, clearAllFileData } = uploadSlice.actions;

export default uploadSlice.reducer;
