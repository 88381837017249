export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reader.onerror = (error) => reject(new Error(error));
  });
}

export const generateFormWithFilePayloads = async ({
  dataPropertyName = "data",
  data,
  filePropertyName = "image",
  file,
}) => {
  let requestForm = new FormData();
  if (file) {
    requestForm.append(filePropertyName, file);
  }
  if (data) {
    requestForm.append(dataPropertyName, new Blob([JSON.stringify(data)], { type: "application/json" }));
  }

  const imageUrl = file ? await getBase64(file) : "";

  return {
    requestForm,
    imageUrl,
  };
};
