import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  checkLoading,
  getResponseStatus,
  getRequestStatus,
  getErrorStatus,
  setStatusDefaults,
  showToast,
} from "@utils/redux";

import CatalogService from "./catalogApi";
import moment from "moment";

export const catalogThunks = {
  getCatalogFromWebshop: createAsyncThunk("webshops/getCatalogFromWebshop", async (webshopId, { rejectWithValue }) => {
    try {
      const res = await CatalogService.getCatalogFromWebshop(webshopId);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),

  getPricingTiers: createAsyncThunk("webshops/getPricingTiers", async (_, { rejectWithValue }) => {
    try {
      const res = await CatalogService.getPricingTiers();
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),

  createItem: createAsyncThunk("webshops/createItem", async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await CatalogService.createItem(payload, selectedWebshop.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),

  updateItem: createAsyncThunk("webshops/updateItem", async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
          catalog: { selectedItem },
        },
      } = getState();
      const res = await CatalogService.updateItem(payload, selectedWebshop.id, selectedItem.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),

  updateImage: createAsyncThunk("webshops/updateImage", async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
          catalog: { selectedItem },
        },
      } = getState();
      const res = await CatalogService.updateImage(payload, selectedWebshop.id, selectedItem.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),

  deleteItem: createAsyncThunk("webshops/deleteItem", async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      // The service returns a 200 only
      await CatalogService.deleteItem(payload, selectedWebshop.id);
      // We want to return the deleted item so we can update the catalog
      return payload;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  generateDeepLink: createAsyncThunk("webshops/generateDeepLink", async (payload, { getState, rejectWithValue }) => {
    const { openNewItem } = payload;
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await CatalogService.generateDeepLink(selectedWebshop.id);
      return { ...res.data, openNewItem };
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  verifyDeepLink: createAsyncThunk("webshops/verifyDeepLink", async (payload, { getState, rejectWithValue }) => {
    try {
      const {
        webshops: {
          webshops: { selectedWebshop },
        },
      } = getState();
      const res = await CatalogService.verifyDeepLink(payload, selectedWebshop.id);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
};

const ITEM_DEFAULT_VALUES = {
  id: "",
  name: "",
  externalId: "",
  available: true,
  startTime: new Date(moment().minutes(0).toDate()),
  endTime: "",
  image: "",
  pricingTierLevelId: null,
  totalQuantity: "",
  remainingQuantity: "",
  quantityPerPlayer: "",
  categoryId: "",
  badgeId: "",
  description: "",
  recurrenceInterval: null,
  deepLinkCode: "",
};

const SERIES_DEFAULT_VALUES = {
  available: true,
  items: [],
  name: "",
  endParameter: "LOOP",
  endTime: "",
  startTime: new Date(moment().minutes(0).toDate()),
  recurrenceInterval: null,
};

export const catalogSlice = createSlice({
  name: "catalog",
  initialState: {
    catalog: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    pricingTiers: {
      tiers: null,
      meta: null,
      requestStatus: setStatusDefaults(),
    },
    createItem: {
      data: null,
      requestStatus: setStatusDefaults(),
    },
    deleteItem: {
      requestStatus: setStatusDefaults(),
    },
    updateItem: {
      requestStatus: setStatusDefaults(),
    },
    generateDeepLink: {
      requestStatus: setStatusDefaults(),
    },
    deepLinkAvailable: {
      data: {
        available: true,
        alternatives: [],
      },
      requestStatus: setStatusDefaults(),
    },
    draftItem: {
      category: null,
      badge: null,
      playerSegmentIds: [],
    },
    defaultItem: {
      ...ITEM_DEFAULT_VALUES,
    },
    selectedItem: null,
    deepLinkCode: null,
    series: {
      defaultSeries: { ...SERIES_DEFAULT_VALUES },
      selectedSeries: null,
      createSeries: {
        data: null,
        requestStatus: setStatusDefaults(),
      },
      deleteSeries: {
        requestStatus: setStatusDefaults(),
      },
      updateSeries: {
        requestStatus: setStatusDefaults(),
      },
    },
  },
  reducers: {
    setDraftItemProps: (state, action) => {
      const { payload = {} } = action;
      state.draftItem = { ...state.draftItem, ...payload };
    },
    setSelectedItem: (state, action) => {
      const { payload = {} } = action;
      state.selectedItem = payload;
    },
    setSelectedSeries: (state, action) => {
      const { payload = {} } = action;
      state.series.selectedSeries = payload;
    },
    resetDeepLinkAvailable: (state) => {
      state.deepLinkAvailable = {
        data: {
          available: true,
          alternatives: [],
        },
        requestStatus: setStatusDefaults(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Get Catalog From Webshop
       *******************************************************************************/
      .addCase(catalogThunks.getCatalogFromWebshop.pending, (state) => {
        state.catalog.requestStatus = getRequestStatus();
      })
      .addCase(catalogThunks.getCatalogFromWebshop.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.catalog.data = payload;

        // we should remove this when we have the endpoint updated
        state.catalog.data.series = [];
        state.catalog.requestStatus = getResponseStatus();
      })
      .addCase(catalogThunks.getCatalogFromWebshop.rejected, (state) => {
        state.catalog.requestStatus = getErrorStatus();
      })

      /*******************************************************************************
       * Get Pricing Tiers
       *******************************************************************************/
      .addCase(catalogThunks.getPricingTiers.pending, (state) => {
        state.pricingTiers.requestStatus = getRequestStatus();
      })
      .addCase(catalogThunks.getPricingTiers.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { tiers, meta } = payload;
        state.pricingTiers.tiers = tiers;
        state.pricingTiers.meta = meta;
        state.pricingTiers.requestStatus = getResponseStatus();
      })
      .addCase(catalogThunks.getPricingTiers.rejected, (state) => {
        state.pricingTiers.requestStatus = getErrorStatus();
      })

      /*******************************************************************************
       * Create Item
       *******************************************************************************/
      .addCase(catalogThunks.createItem.pending, (state) => {
        state.createItem.requestStatus = getRequestStatus();
      })
      .addCase(catalogThunks.createItem.fulfilled, (state, action) => {
        const { payload = [] } = action;
        state.createItem.data = payload;
        // Add pricing tier to the catalog pricing tiers
        const tiers = state.pricingTiers.tiers;
        const { id, ...rest } = tiers.filter((tier) => tier.id === payload.pricingTierLevelId)[0];

        if (id) {
          state.catalog.data.tiers = { ...state.catalog.data.tiers, [id]: { ...rest } };
        }
        // Add the new item to the catalog
        state.catalog.data.items = [payload, ...state.catalog.data.items];
        state.deepLinkCode = state.deepLinkCode === payload.deepLinkCode ? null : state.deepLinkCode;
        state.defaultItem = { ...ITEM_DEFAULT_VALUES, deepLinkCode: state.deepLinkCode };
        state.deepLinkAvailable.data = { alternatives: [], available: true };
        state.createItem.requestStatus = getResponseStatus();
      })
      .addCase(catalogThunks.createItem.rejected, (state) => {
        state.createItem.requestStatus = getErrorStatus();
      })

      /*******************************************************************************
       * Update Item
       *******************************************************************************/
      .addCase(catalogThunks.updateItem.pending, (state) => {
        state.updateItem.requestStatus = getRequestStatus();
      })
      .addCase(catalogThunks.updateItem.fulfilled, (state, action) => {
        const { payload = {} } = action;
        // get all the pricing tiers and filter with the one of the updated item
        const tiers = state.pricingTiers.tiers;
        const { id, ...rest } = tiers.filter((tier) => tier.id === payload.pricingTierLevelId)[0];

        if (id && !state.catalog.data.tiers[id]) {
          state.catalog.data.tiers = { ...state.catalog.data.tiers, [id]: { ...rest } };
        }

        const updatedItems = state.catalog.data.items.map((item) => (item.id === payload?.id ? payload : item));

        state.catalog.data.items = updatedItems;
        state.updateItem.requestStatus = getResponseStatus();
      })
      .addCase(catalogThunks.updateItem.rejected, (state) => {
        state.updateItem.requestStatus = getErrorStatus();
      })

      /*******************************************************************************
       * Delete Item
       *******************************************************************************/
      .addCase(catalogThunks.deleteItem.pending, (state) => {
        state.deleteItem.requestStatus = getRequestStatus();
      })
      .addCase(catalogThunks.deleteItem.fulfilled, (state, action) => {
        const { payload = [] } = action;

        const items = [...state.catalog.data.items];
        const removedItemIndex = items.findIndex((item) => item.id === payload);
        if (removedItemIndex > -1) {
          items.splice(removedItemIndex, 1);
        }
        state.catalog.data.items = items;
        state.deleteItem.requestStatus = getResponseStatus();
      })
      .addCase(catalogThunks.deleteItem.rejected, (state) => {
        state.deleteItem.requestStatus = getErrorStatus();
      })

      /*******************************************************************************
       * Update Image
       *******************************************************************************/
      .addCase(catalogThunks.updateImage.pending, (state) => {
        state.updateItem.requestStatus = getRequestStatus();
      })
      .addCase(catalogThunks.updateImage.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const updatedItems = state.catalog.data.items.map((item) => (item.id === payload?.id ? payload : item));

        state.catalog.data.items = updatedItems;
        state.updateItem.requestStatus = getResponseStatus();
      })
      .addCase(catalogThunks.updateImage.rejected, (state) => {
        state.updateItem.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Generate Deep Link
       *******************************************************************************/
      .addCase(catalogThunks.generateDeepLink.pending, (state) => {
        state.generateDeepLink.requestStatus = getRequestStatus();
      })
      .addCase(catalogThunks.generateDeepLink.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { deepLinkCode, openNewItem } = payload;

        state.defaultItem.deepLinkCode = deepLinkCode;
        state.deepLinkCode = deepLinkCode;

        openNewItem();
        state.generateDeepLink.requestStatus = getResponseStatus();
      })
      .addCase(catalogThunks.generateDeepLink.rejected, (state) => {
        showToast("Something went wrong. Please, try again.", "error");
        state.generateDeepLink.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Verify Deep Link
       *******************************************************************************/
      .addCase(catalogThunks.verifyDeepLink.pending, (state) => {
        state.deepLinkAvailable.data = {
          available: true,
          alternatives: [],
        };
        state.deepLinkAvailable.requestStatus = getRequestStatus();
      })
      .addCase(catalogThunks.verifyDeepLink.fulfilled, (state, action) => {
        const { payload = {} } = action;

        const { available, alternatives } = payload;
        const [first, second] = alternatives;

        // we only show two alternatives
        state.deepLinkAvailable.data = { alternatives: [first, second], available };

        state.deepLinkAvailable.requestStatus = getResponseStatus();
      })
      .addCase(catalogThunks.verifyDeepLink.rejected, (state) => {
        state.deepLinkAvailable.requestStatus = getErrorStatus();
      });
  },
});

export const catalogSelector = (state) => state.webshops.catalog;
export const catalogLoadingSelector = (state) => checkLoading(state.webshops.catalog.catalog);
export const pricingTiersSelector = (state) => state.webshops.catalog.pricingTiers;
export const pricingTiersLoadingSelector = (state) => checkLoading(state.webshops.catalog.pricingTiers);
export const selectedItemSelector = (state) => state.webshops.catalog.selectedItem;
export const catalogItems = (state) => state.webshops.catalog.catalog.data.items;
export const deepLinkAvailableSelector = (state) => state.webshops.catalog.deepLinkAvailable;
export const catalogSeries = (state) => state.webshops.catalog.catalog.data.series;
export const seriesCatalogSelector = (state) => state.webshops.catalog.series;

export const catalogPageLoadingSelector = (state) => {
  const catalogLoading = catalogLoadingSelector(state);
  const pricingTiersLoading = pricingTiersLoadingSelector(state);
  const data = state?.webshops?.catalog?.catalog?.data;
  return catalogLoading || pricingTiersLoading || !data;
};

export const catalogNewItemLoadingSelector = (state) => checkLoading(state.webshops.catalog.generateDeepLink);

export const { setSelectedItem, setDraftItemProps, setSelectedSeries, resetDeepLinkAvailable } = catalogSlice.actions;
export default catalogSlice.reducer;
