import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/reducers";
import middlewares from "./redux/middlewares";

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // TODO: Disabling for now, as we move more towards slices we should
        // look into enabling this again
        serializableCheck: false,
      }).concat(middlewares),
    preloadedState,
  });

export const store = setupStore();
