import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { BodyRegular16 } from "@components/styled";
import StyledAuthenticationContainer from "./StyledAuthenticationContainer";

import { getBrandingInfo } from "@utils/branding";

import { useLogout } from "@utils/custom-hooks/useAuth";

const SANLO_LOGO = require("@assets/img/logos/sanlo_logo_white.svg").default;

const AuthenticationContainer = ({
  children,
  error,
  hasLogout = false,
  logOutText = "Log Out",
  noLogo = false,
  partnerIntro = false,
  onShowChildren = () => {},
  ...props
}) => {
  const appLogout = useLogout();

  const partnerBranding = useSelector((state) => state.session.partnerBranding);

  const [partnerImageLoaded, setPartnerImageLoaded] = useState(false);
  const [sanloImageLoaded, setSanloImageLoaded] = useState(false);
  const [brandingReady, setBrandingReady] = useState(false);
  const [introReady, setIntroReady] = useState(false);
  const [showChildren, setShowChildren] = useState(partnerIntro ? false : true);

  const leftGrad = useRef();
  const rightGrad = useRef();

  const { name: partnerName, logo: partnerLogo, site: partnerSite } = partnerBranding.data || {};
  const { isBranded, fromMarketing } = getBrandingInfo();

  const interactiveBackground = (e) => {
    const _w = window.innerWidth / 2;
    const _h = window.innerHeight / 2;
    const _mouseX = e.clientX;
    const _mouseY = e.clientY;

    const xL = `${-400 - (_mouseX - _w) * 0.2}px`;
    const yL = `${50 - (_mouseY - _h) * 0.2}px`;
    const xR = `${-200 + (_mouseX - _w) * 0.3}px`;
    const yR = `${300 + (_mouseY - _h) * 0.3}px`;

    if (leftGrad.current) {
      leftGrad.current.style.top = yL;
      leftGrad.current.style.left = xL;
    }

    if (rightGrad.current) {
      rightGrad.current.style.top = yR;
      rightGrad.current.style.right = xR;
    }
  };

  useEffect(() => {
    checkPreload();
    // Set events for the interactive background
    window.addEventListener("mousemove", interactiveBackground);
    return function cleanup() {
      window.removeEventListener("mousemove", interactiveBackground);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showChildren) {
      onShowChildren();
    }
  }, [showChildren, onShowChildren]);

  useEffect(() => {
    checkPreload();
    // eslint-disable-next-line
  }, [partnerLogo, partnerBranding.requestStatus]);

  useEffect(() => {
    if (sanloImageLoaded === "error" || partnerImageLoaded === "error") {
      setShowChildren(true);
      return;
    }

    if (brandingReady) {
      setIntroReady(true);
      setTimeout(() => {
        setShowChildren(true);
      }, 1750);
    }
    // eslint-disable-next-line
  }, [brandingReady]);

  useEffect(() => {
    // "error" will trigger true for these, which is what we want
    if (partnerImageLoaded && sanloImageLoaded) {
      setBrandingReady(true);
    }
  }, [partnerImageLoaded, sanloImageLoaded]);

  const checkPreload = () => {
    if (partnerBranding.requestStatus.completed && !partnerLogo) {
      setPartnerImageLoaded("error");
      setSanloImageLoaded("error");
      return;
    }
    if (!partnerLogo) return;

    // Preload the partner image
    const preloadPI = new Image();
    preloadPI.onload = () => {
      setPartnerImageLoaded(preloadPI.src);
    };
    preloadPI.onerror = () => {
      setPartnerImageLoaded("error");
    };
    preloadPI.src = partnerLogo;

    // Preload the sanlo logo
    const preloadSI = new Image();
    preloadSI.onload = () => {
      setSanloImageLoaded(preloadSI.src);
    };
    preloadSI.onerror = () => {
      setSanloImageLoaded("error");
    };
    preloadSI.src = SANLO_LOGO;
  };

  const renderSanloLogo = () => {
    if (noLogo) return null;

    if (isBranded) {
      // Check for brandingReady so the back link shows at the same time
      // as the logos, this also gives the font a small headstart to load
      if (partnerSite && partnerName && brandingReady) {
        return (
          <a
            className={classnames("partner-site-link", {
              hide: !showChildren,
            })}
            href={partnerSite}
          >
            {fromMarketing ? `<- Visit ${partnerName}` : `<- Back to ${partnerName}`}
          </a>
        );
      }
      return null;
    }

    return (
      <a className="main-sanlo-logo" href="https://sanlo.io/">
        <img className="sanlo-logo" src={require("@assets/img/logos/sanlo_logo_white.svg").default} alt="" />
      </a>
    );
  };

  const renderPartnerBranding = () => {
    if (noLogo || !partnerLogo || !brandingReady) return null;
    if (sanloImageLoaded === "error" || partnerImageLoaded === "error") return null;

    return (
      <div className="branding-container">
        <img className="partner-branding-img" src={partnerImageLoaded} alt="partner-logo" />
        <div className="branding-divider">+</div>
        <img className="sanlo-branding-img" src={sanloImageLoaded} alt="Sanlo logo" />
      </div>
    );
  };

  return (
    <StyledAuthenticationContainer {...props}>
      <div
        className={classnames("authentication-container-header", {
          "partner-intro": isBranded && brandingReady && introReady && partnerIntro,
        })}
      >
        {renderSanloLogo()}
        {renderPartnerBranding()}

        {hasLogout && (
          <BodyRegular16
            className="logout-button"
            onClick={() => {
              appLogout();
            }}
          >
            {logOutText}
          </BodyRegular16>
        )}
      </div>

      <div ref={leftGrad} id="leftGrad" className={error ? "error" : ""} />
      <div ref={rightGrad} id="rightGrad" className={error ? "error" : ""} />

      <div
        className={classnames("authentication-children", {
          hide: !showChildren,
        })}
      >
        {children}
      </div>
    </StyledAuthenticationContainer>
  );
};

export default AuthenticationContainer;
