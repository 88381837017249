import React from "react";
import { Box, Button, Icon, Text } from "@chakra-ui/react";

export const BaseSection = ({ selected = false, style = {}, children }) => {
  return (
    <Box
      width="100%"
      borderRadius="8px"
      bg={`var(--builder-section-bg${selected ? "-selected" : ""})`}
      border={`var(--builder-section-border${selected ? "-selected" : ""})`}
      cursor="pointer"
      {...style}
    >
      {children}
    </Box>
  );
};

const ICON_SIZE = "16px";
export const SectionIcon = ({ icon = null, selected = false, fill = "white" }) => {
  return <Icon as={icon} fill={fill} opacity={selected ? 1 : 0.5} width={ICON_SIZE} height={ICON_SIZE} />;
};
export const SectionText = ({ selected = false, children }) => {
  return (
    <Text color="white" fontSize="sm" opacity={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {children}
    </Text>
  );
};
export const SectionIconButton = ({ icon = null, selected = false, onClick = () => {} }) => {
  return (
    <Button
      bg="transparent !important"
      p="0 !important"
      w={`${ICON_SIZE} !important`}
      h={`${ICON_SIZE} !important`}
      minWidth={`${ICON_SIZE} !important`}
      cursor="pointer"
      onClick={onClick}
    >
      <SectionIcon icon={icon} selected={selected} />
    </Button>
  );
};
