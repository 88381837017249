export default {
  storefront: {
    theme: {
      primary: {
        type: "COLOR",
        color: "#FFFFFF",
        opacityHundredths: 100,
        name: "PRIMARY",
      },
      primaryContrast: {
        type: "COLOR",
        color: "#000000",
        opacityHundredths: 100,
        name: "PRIMARY_CONTRAST",
      },
      secondary: {
        type: "COLOR",
        color: "#ffffff",
        opacityHundredths: 100,
        name: "SECONDARY",
      },
      secondaryContrast: {
        type: "COLOR",
        color: "#000000",
        opacityHundredths: 100,
        name: "SECONDARY_CONTRAST",
      },
      tertiary: {
        type: "COLOR",
        color: "#ffffff",
        opacityHundredths: 100,
        name: "TERTIARY",
      },
      tertiaryContrast: {
        type: "COLOR",
        color: "#000000",
        opacityHundredths: 100,
        name: "TERTIARY_CONTRAST",
      },
      text: {
        type: "COLOR",
        color: "#000000",
        opacityHundredths: 100,
        name: "TEXT",
      },
      title: {
        type: "COLOR",
        color: "#000000",
        opacityHundredths: 100,
        name: "TITLE",
      },
      background: {
        type: "COLOR",
        color: "#ffffff",
        opacityHundredths: 100,
        name: "BACKGROUND",
      },
    },
    settings: {
      faviconUrl: "",
      socialSharingImageUrl: "",
      title: "",
      description: "",
      purchaseWebhookUrl: "",
      sdkClientToken: "",
      customDomains: {
        status: "",
        useCustomDomains: false,
        internalDomain: "",
        customDomain: "",
        records: [],
      },
      hmacKey: {
        metadata: null,
        value: null,
      },
    },
    manualAuthConfig: {
      idLabel: "",
      webhookUrl: "",
      instructionText: "",
      instructionImageUrl: "",
      visible: false,
    },
  },
  layout: {
    header: {},
    footer: {},
    login: {},
    deepLink: {},
    pages: [
      {
        name: "Home",
        sections: [],
      },
    ],
  },
};
