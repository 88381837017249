import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Text,
  VStack,
  chakra,
  useToast,
} from "@chakra-ui/react";
import DefaultImg from "@assets/img/icons/adNetworkDefault.png";
import { ReactComponent as ArrowLeft } from "@src/assets/img/icons/common/arrow_left.svg";
import { useDispatch, useSelector } from "react-redux";
import { adNetworksSelector, adNetworksThunks } from "@redux/integrations/ad-networks/adNetworksSlice";
import { useForm } from "react-hook-form";
import { integrationsConnectionsThunks } from "@redux/integrations/base/baseSlice";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { ReactComponent as AlertIcon } from "@assets/img/icons/common/alertCircle.svg";
import { useHubspotChat } from "@src/utils/custom-hooks/useHubspotChat";
import { Spinner } from "reactstrap";
import { Toast } from "@common/toast/Toast";
import { trackGTMEvent, ADD_NETWORKS_ACTION, COMMON_ACTION } from "@utils/gtm";

const Form = chakra("form");
export function AdNetworkCredentials({ handleNextStep, handlePrevStep }) {
  const {
    selectedAdNetwork,
    addCredentials: { requestStatus },
  } = useSelector(adNetworksSelector);
  const {
    register,
    handleSubmit,
    setError,
    formState: { isDirty, errors, isValid },
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const { hasLoaded, openHandler } = useHubspotChat();
  const isLoading = selectedAdNetwork.requestStatus.pending;
  const toast = useToast({
    render: Toast,
    isClosable: true,
    status: "error",
  });

  const requiredCredentials = selectedAdNetwork.data?.requiredCredentials;

  const trackEvent = (action) => {
    trackGTMEvent({
      event: action,
    });
  };

  async function handleOnSubmit(values) {
    trackEvent(`${ADD_NETWORKS_ACTION.MODAL} - ${COMMON_ACTION.CONNECT} - ${selectedAdNetwork.data.name} - Click`);
    const data = Object.entries(values).filter(([_, value]) => value !== "");
    try {
      const result = await dispatch(adNetworksThunks.addCredentials({ credentials: data })).unwrap();
      if (result) {
        await dispatch(integrationsConnectionsThunks.getIntegrationsConnections());
        handleNextStep();
      }
    } catch (e) {
      if (e.rejectedCredentials) {
        const { rejectedCredentials } = e;
        await dispatch(integrationsConnectionsThunks.getIntegrationsConnections());
        return rejectedCredentials.forEach((credential) => {
          setError(credential.id, { type: "custom" });
        });
      }
      return toast();
    }
  }

  function formIsDisabled() {
    const allFields = watch();
    const fieldsAreEmpty = Object.values(allFields).every((field) => field === "");

    if (fieldsAreEmpty || !isDirty) {
      return true;
    }
    return false;
  }

  function handleContactUs() {
    if (hasLoaded) {
      trackEvent(`${ADD_NETWORKS_ACTION.MODAL} - ${COMMON_ACTION.CONTACT_US} - Click`);
      openHandler();
    }
  }

  if (isLoading) {
    return (
      <Flex h="424px" justifyContent={"center"} alignItems={"center"} color={"brandRed.400"}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <Box bg="dark.600" borderRadius={"10px"}>
        <HStack gap="16px" p="16px">
          <Image src={selectedAdNetwork.data?.iconUrl} fallbackSrc={DefaultImg} w="24px" h="24px" borderRadius="6px" />
          <Text textTransform="capitalize" size="large" fontWeight={500} textColor="textWhite.400">
            {selectedAdNetwork.data?.name}
          </Text>
        </HStack>
        <Divider my={0} />
        <Box p="16px">
          <Box p="24px" borderRadius={"10px"} border="1px solid" borderColor={"dark.200"} bg={"dark.500"}>
            <Text size="small">This integration requires an API key instead of your password. </Text>
          </Box>
          <Box mt="16px">
            <VStack gap="32px">
              {requiredCredentials.map((credential) => (
                <FormControl key={credential.id} isInvalid={errors[credential?.id]}>
                  <FormLabel
                    display={"flex"}
                    justifyContent={"space-between"}
                    fontSize="sm"
                    color={isValid ? "textSubtext.400" : "brandRed.400"}
                  >
                    {credential.label}
                    <Link
                      fontWeight={500}
                      color="brandRed.400"
                      isExternal
                      href={`https://www.sanlo.io/integrations#${selectedAdNetwork.data.slug}`}
                      onClick={() =>
                        trackEvent(`${ADD_NETWORKS_ACTION.MODAL} - Show Me How - ${credential.label} - Click`)
                      }
                    >
                      Show me how &#8594;
                    </Link>
                  </FormLabel>
                  <Input {...register(credential.id)} />
                  {errors[credential?.id] && (
                    <HStack gap="4px" mt="8px">
                      <Icon as={AlertIcon} w="16px" h="16px" color="brandRed.400" />
                      <Text fontSize={"sm"}>
                        Invalid input, if this problem persists, please{" "}
                        <Button variant="link" color="brandRed.400" fontWeight={500} onClick={handleContactUs}>
                          contact us
                        </Button>
                        .
                      </Text>
                    </HStack>
                  )}
                </FormControl>
              ))}
            </VStack>
          </Box>
        </Box>
      </Box>
      <HStack w={"100%"} gap={"16px"} mt={requiredCredentials.length > 1 ? "89px" : "190px"}>
        <Button
          onClick={handlePrevStep}
          flexBasis={"129px"}
          flexShrink={0}
          w={"100%"}
          size={"lg"}
          leftIcon={<Icon fill={"white"} as={ArrowLeft} w="16px" height="16px" />}
        >
          Back
        </Button>
        <Button
          spinner={<StyledLoader />}
          w={"100%"}
          type="submit"
          variant={"primary"}
          size={"lg"}
          isDisabled={formIsDisabled()}
          isLoading={requestStatus.pending}
        >
          Connect to {selectedAdNetwork.data?.name}
        </Button>
      </HStack>
    </Form>
  );
}
