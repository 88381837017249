import React from "react";
import { HStack } from "@chakra-ui/react";

import { BaseSection, SectionText, SectionIcon } from "@components/builder/SidebarTabs";

import { ReactComponent as Globe } from "@assets/img/icons/common/globe.svg";

export const GlobalSidebar = ({ selected = false, onClick = () => {} }) => {
  return (
    <BaseSection
      selected={selected}
      style={{
        borderRadius: "10px",
      }}
    >
      <HStack padding="16px" gap="8px" onClick={onClick}>
        <SectionIcon icon={Globe} selected={selected} />
        <SectionText selected={selected}>Global</SectionText>
      </HStack>
    </BaseSection>
  );
};
