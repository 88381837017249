import React, { useState, forwardRef } from "react";
import { Box, Button, Flex, Icon, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useFormContext, Controller } from "react-hook-form";

import { ReactComponent as CalendarIcon } from "@assets/img/icons/common/calendar.svg";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/cross3.svg";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import "./custom-datepicker.css";

/**
 * @prop {string} registerName - Name of the register.
 * @prop {boolean} hasError - Error state.
 * @prop {boolean} isAvailable - Availability state.
 * @prop {function} onDateChange - Callback function to change the date.
 */
export const DatePickerComponent = ({ registerName = "", hasError = false, isAvailable = false, onDateChange }) => {
  const { control } = useFormContext();
  const { isOpen, onToggle } = useDisclosure();
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [prevDate, setPrevDate] = useState(null);

  const handleCloseOnChange = (newDate, event) => {
    // if event is not undefined, it means that select a day from the calendar
    // if event is undefined, it means that select a time from the time picker

    if (!prevDate) {
      setPrevDate(newDate);
      return;
    }

    setPrevDate(newDate);

    const currentTime = moment(newDate, "h:mm A").format("h:mm A");
    const previousTime = moment(prevDate, "h:mm A").format("h:mm A");

    if (currentTime !== previousTime) {
      onToggle();
    }

    if (moment(newDate).isSame(moment(prevDate), "day") && !event) {
      onToggle();
    }
  };

  return (
    <Box w={"full"}>
      <Controller
        control={control}
        name={registerName}
        render={({ field: { value, onChange } }) => (
          <DatePicker
            open={isOpen}
            selected={value}
            dateFormat={"MMMM do, yyyy h:mm aa"}
            timeFormat="h:mm aa"
            timeIntervals={60}
            filterDate={(date) => filterDate(date, currentMonth)}
            onChange={(date, event) => {
              handleCloseOnChange(date, event);
              if (onDateChange) onDateChange(date);
              return onChange(date);
            }}
            onMonthChange={(date) => setCurrentMonth(moment(date))}
            customInput={
              <CustomInput
                date={value}
                isOpen={isOpen}
                onToggle={onToggle}
                clearDate={() => {
                  if (onDateChange) onDateChange("");
                  onChange("");
                }}
                hasError={hasError}
                isAvailable={isAvailable}
                registerName={registerName}
              />
            }
            calendarContainer={CustomCalendarContainer}
            renderDayContents={(day, date) => (
              <CustomDayContents day={day} date={date} currentMonth={currentMonth} selectedDate={value} />
            )}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <CustomMonthHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
            )}
            showTimeSelect
            onClickOutside={(event) => {
              const clickedElement = event.target;
              if (clickedElement.id !== "custom-input") {
                onToggle();
              }
            }}
          />
        )}
      />
    </Box>
  );
};
DatePickerComponent.propTypes = {
  registerName: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  isAvailable: PropTypes.bool,
  onDateChange: PropTypes.func,
};

/**
 * @prop {date} date - selected date
 * @prop {string} value - formatted date "MMMM do, yyyy h:mm aa"
 * @prop {string} registerName - name of the register.
 * @prop {boolean} isOpen - if DatePicker is open
 * @prop {function} onToggle - toggle DatePicker
 * @prop {function} clearDate - clear date selected
 * @prop {boolean} hasError - if selected date has error
 * @prop {boolean} isAvailable - if date is available
 */

const CustomInput = forwardRef(
  ({ date, value, registerName, isOpen, onToggle, clearDate, hasError, isAvailable }, ref) => {
    // date is the chosen date
    // value is a string with the chosen date formatted ("MMMM do, yyyy h:mm aa")

    const [isHovered, setIsHovered] = useState(false);

    const handleClearData = (e) => {
      e.stopPropagation();
      clearDate();
    };

    const isToday = moment(date).isSame(moment(), "day");
    const applyStyles = value;
    const showCross = (isHovered || isOpen) && value;

    const getDisplayValue = () => {
      if (!value && !isAvailable) {
        return "Select";
      } else if (!value) {
        return "Continuous";
      }
      return isToday ? `Today, ${moment(date).minutes(0).format("h:mm A")}` : value;
    };

    const getBorderColor = () => (isOpen || hasError) && "brandRed.400";
    const getPadding = () => (isOpen || hasError ? "10px" : "12px");
    const getIconFill = () => (applyStyles ? "textWhite.400" : "textSubtext.400");

    return (
      <Button
        id={"custom-input"}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        w={"full"}
        border={getBorderColor() && "2px solid"}
        borderColor={getBorderColor()}
        justifyContent={"space-between"}
        color={getIconFill()}
        onClick={onToggle}
        ref={ref}
        p={getPadding()}
        background={"dark.400"}
        borderRadius={"10px"}
        rightIcon={
          <Box pos={"relative"}>
            {showCross ? (
              <Box
                pos={"absolute"}
                top={"-12px"}
                right={"-4px"}
                p={"4px"}
                borderRadius={"4px"}
                backgroundColor={"rgba(33, 33, 38, 0.78)"}
              >
                <Icon as={CrossIcon} onClick={handleClearData} w={"16px"} h={"16px"} fill={"textWhite.400"} />
              </Box>
            ) : (
              <Icon as={CalendarIcon} w={"16px"} h={"16px"} fill={getIconFill()} />
            )}
          </Box>
        }
        data-testid={`date-picker-input-${registerName}`}
      >
        {getDisplayValue()}
      </Button>
    );
  },
);

CustomInput.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  value: PropTypes.string,
  registerName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  clearDate: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  isAvailable: PropTypes.bool,
};
CustomInput.displayName = "CustomInput"; // Add display name to the component

export default CustomInput; // Export the component

/**
 * @prop {node} children - children nodes
 */
const CustomCalendarContainer = ({ children }) => {
  return (
    <Box
      position={"relative"}
      w={"396px"}
      borderRadius={"10px"}
      boxShadow={"0 2px 10px rgba(0, 0, 0, 0.1)"}
      backgroundColor={"dark.400"}
      border={"1px solid"}
      borderColor={"dark.400"}
    >
      <VStack width="100%" height="100%" justifyContent={"space-between"} alignItems={"stretch"}>
        {children}
      </VStack>
    </Box>
  );
};
CustomCalendarContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * @prop {date} date - selected date
 * @prop {function} decreaseMonth - change to previous month
 * @prop {function} increaseMonth - change to next month
 */
const CustomMonthHeader = ({ date, decreaseMonth, increaseMonth }) => {
  const currentDate = moment();
  const prevMonth = moment(date).subtract(1, "months");

  const isPastMonth =
    prevMonth.isBefore(currentDate, "month") &&
    !(prevMonth.month() === currentDate.month() && prevMonth.year() === currentDate.year());

  return (
    <Flex
      p={"16px 12px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      backgroundColor={"dark.400"}
      borderRadius={"8px"}
    >
      <Icon
        color={isPastMonth ? "textSubtext.400" : "textWhite.400"}
        as={ChevronLeftIcon}
        w={"24px"}
        height={"24px"}
        cursor={isPastMonth ? "default" : "pointer"}
        onClick={() => !isPastMonth && decreaseMonth()}
        userSelect={"none"}
        borderRadius={"8px"}
        _hover={!isPastMonth && { backgroundColor: "rgba(33, 33, 38, 0.78)" }}
        data-testid={"decrease-month"}
      />
      <Box>
        <Text size={"regular"} userSelect={"none"} color={"#C5C5C5"}>
          {date.toLocaleString("default", { month: "long", year: "numeric" })}
        </Text>
      </Box>
      <Icon
        color={"textWhite.400"}
        as={ChevronRightIcon}
        w={"24px"}
        height={"24px"}
        cursor={"pointer"}
        onClick={increaseMonth}
        userSelect={"none"}
        borderRadius={"8px"}
        _hover={{ backgroundColor: "rgba(33, 33, 38, 0.78)" }}
        data-testid={"increase-month"}
      />
    </Flex>
  );
};

CustomMonthHeader.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
};

/**
 * @prop {number} day - day of the month
 * @prop {date} date - selected date
 * @prop {date} currentMonth - current month
 * @prop {date} selectedDate - selected date
 */
const CustomDayContents = ({ day, date, currentMonth, selectedDate }) => {
  const startOfMonth = moment(currentMonth).startOf("month");
  const endOfMonth = moment(currentMonth).endOf("month");
  const isPast = moment(date).isBefore(moment()) && !moment(date).isSame(moment(), "day");

  const isBetweenStartAndEndOfMonth = moment(date).isBetween(startOfMonth, endOfMonth, null, "[]");
  if (!isBetweenStartAndEndOfMonth) {
    return <Box as={"span"}></Box>;
  }

  const isSelected = selectedDate && moment(date).isSame(selectedDate, "day");
  const selectedTextColor = isSelected ? "brandRed.400" : "textSubtext.400";
  const textColor = isPast ? "rgba(250, 248, 248, 0.10)" : selectedTextColor;

  return (
    <Flex
      w={"full"}
      h={"full"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"8px"}
      padding={isSelected ? "13px 9px" : "16px 12px"}
      border={isSelected && "3px solid"}
      borderColor={isSelected && "brandRed.400"}
      backgroundColor={isSelected && "rgba(255, 92, 121, 0.10)"}
      _hover={{
        backgroundColor: !isPast && "rgba(255, 92, 121, 0.10)",
      }}
      cursor={!isPast && "pointer"}
    >
      <Text size={"small"} color={textColor} userSelect={"none"}>
        {day}
      </Text>
    </Flex>
  );
};

CustomDayContents.propTypes = {
  day: PropTypes.number.isRequired,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  currentMonth: PropTypes.instanceOf(moment),
  selectedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
};

const filterDate = (date, currentMonth) => {
  return (
    (moment(date).isSame(moment(), "day") || moment(date).isAfter(moment())) &&
    moment(date).month() === currentMonth.month()
  );
};
