import React, { useEffect } from "react";
import {
  Accordion,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Icon,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Section, Availability } from "@components/catalog";
import { General, ItemsList } from "./components";
import { ConfirmationModal } from "@common/modals/confirmation-modal";

import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { seriesCatalogSelector } from "@redux/webshops/catalog/catalogSlice";

import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { ReactComponent as TrashIcon } from "src/assets/img/icons/common/trash-icon.svg";
import { AVAILABILITY_TYPE } from "@src/consts/webshops/catalog";
import { getFormattedForm } from "./utils";

import PropTypes from "prop-types";

/**
 * @prop {boolean} isOpen - Drawer visibility state.
 * @prop {function} onClose - Callback function to close the drawer.
 */
export const AddSeries = ({ isOpen, onClose }) => {
  const { defaultSeries = {}, selectedSeries = null } = useSelector(seriesCatalogSelector);
  const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  const onSubmit = (data) => {
    console.log("DATA", data);
  };

  function handleRemoveItem() {
    // dispatch delete series
    onCloseModal();
    onClose();
  }

  function cleanAndClose() {
    reset(defaultSeries);
    onClose();
  }

  useEffect(() => {
    if (selectedSeries) {
      reset(getFormattedForm(selectedSeries));
    } else {
      reset(defaultSeries);
    }
  }, [defaultSeries, reset, selectedSeries]);

  return (
    <>
      <FormProvider {...methods}>
        <Drawer isOpen={isOpen} placement={"right"} onClose={onClose} onCloseComplete={onClose}>
          <DrawerContent p="32px" pb="16px" maxW="460px" data-test-id={"catalog-add-series-drawer"}>
            <DrawerHeader userSelect={"none"}>
              {selectedSeries ? "Edit series" : "New series"}
              <DrawerCloseButton right="32px" w={"24px"} h="24px" />
            </DrawerHeader>

            <DrawerBody pt={"8px"}>
              <form id="series-creation-form" onSubmit={handleSubmit(onSubmit)}>
                <Accordion variant={"catalog"} allowMultiple defaultIndex={[0]}>
                  <Section name={"General"}>
                    <General />
                  </Section>
                  <Section name={"Items"}>
                    <ItemsList />
                  </Section>
                  <Section name={"Availability"}>
                    <Availability type={AVAILABILITY_TYPE.SERIES} />
                  </Section>
                </Accordion>
              </form>
            </DrawerBody>

            <DrawerFooter>
              <VStack w={"100%"} mb={"10px"} gap={"24px"}>
                <Flex gap="24px" w="full">
                  <Button
                    onClick={() => {
                      cleanAndClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    flex="1"
                    variant="primary"
                    type="submit"
                    form="series-creation-form"
                    spinner={<StyledLoader />}
                    data-test-id={"catalog-series-submit"}
                  >
                    {selectedSeries ? "Save changes" : "Add series"}
                  </Button>
                </Flex>
                {selectedSeries && (
                  <Button
                    flex="1"
                    variant="tertiary"
                    color="brandRed.400"
                    leftIcon={<Icon as={TrashIcon} />}
                    onClick={onOpenModal}
                    data-test-id={"catalog-remove-series-submit"}
                  >
                    Remove series
                  </Button>
                )}
              </VStack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </FormProvider>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onAccept={handleRemoveItem}
        title={"Delete Series?"}
        acceptText={"Yes, delete"}
        cancelText={"Cancel"}
      >
        <Text size={"smallAlt"} letterSpacing={"0.14px"}>
          You cannot undo this action. Do you wish to continue?
        </Text>
      </ConfirmationModal>
    </>
  );
};

AddSeries.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
