import {
  Button,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { TagInput } from "@components/common/tag-input/TagInput";
import { ReactComponent as TrashIcon } from "@assets/img/icons/common/trash-icon.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { associationsThunks, categoriesSelector } from "@redux/webshops/associations/associationsSlice";
import { Controller, useFormContext } from "react-hook-form";
import { showToast } from "@src/utils/redux";
import { catalogSelector, setDraftItemProps } from "@redux/webshops/catalog/catalogSlice";
import PropTypes from "prop-types";

export function Categories() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useDispatch();
  const categories = useSelector(categoriesSelector);
  const {
    selectedItem,
    draftItem: { category },
  } = useSelector(catalogSelector);
  const { control } = useFormContext();
  const [categoryToRemove, setCategoryToRemove] = useState(null);

  useEffect(() => {
    if (selectedItem) {
      dispatch(setDraftItemProps({ category: selectedItem.category }));
    }
  }, [selectedItem, dispatch]);

  // We return the selected category id to the onChange function to handle the result in the form submission
  async function handleOnChange(event, newValue, prevValue) {
    if (!newValue) {
      dispatch(setDraftItemProps({ category: null }));
      return null;
    }
    if (typeof newValue === "object") {
      // Here a category was selected from the dropdown
      dispatch(setDraftItemProps({ category: newValue }));
      return newValue.id;
    }

    // this means a new category was entered
    const categoryExists = categories.find((category) => category.name === newValue);
    if (categoryExists) {
      dispatch(setDraftItemProps({ category: categoryExists }));
      return categoryExists.id;
    }
    // http call to create a new category
    try {
      dispatch(setDraftItemProps({ category: { name: newValue } }));
      const newCategory = await dispatch(associationsThunks.createCategory({ name: newValue })).unwrap();
      dispatch(setDraftItemProps({ category: newCategory }));
      return newCategory.id;
    } catch (error) {
      showToast("There was an error creating the category", "error");
      dispatch(setDraftItemProps({ category: null }));
      return null;
    }
  }

  function handleOnDelete() {
    if (categoryToRemove) {
      dispatch(associationsThunks.deleteCategory(categoryToRemove.id));
    }
    if (categoryToRemove.id === category?.id) {
      dispatch(setDraftItemProps({ category: null }));
    }
    onClose();
  }

  async function handleOnUpdateSelected(item) {
    const { editedItem, newValue } = item;

    if (editedItem) {
      dispatch(setDraftItemProps({ category: { id: editedItem.id, name: newValue } }));
      try {
        // http call to edit a category
        await dispatch(associationsThunks.editCategory({ id: editedItem.id, name: newValue })).unwrap();
        // this is for updating the categoryId in the form
        return editedItem.id;
      } catch (error) {
        showToast("There was an error editing the category", "error");
        return null;
      }
    }
  }

  return (
    <>
      <Controller
        name={"categoryId"}
        control={control}
        rules={{ required: true }}
        /*
				 Why dont we use the value from the controller?
				 because we want to have the category id as the value of the registered input and the value that expects TagInput is an object
				 with the same signature of options prop
				*/
        render={({ field: { onChange }, fieldState }) => (
          <TagInput
            data-test-id="tag-input-categories"
            id={"categories"}
            hasError={fieldState.error}
            onChange={async (event, newValue, prevValue) => onChange(await handleOnChange(event, newValue, prevValue))}
            value={category}
            options={categories}
            displayField={"name"}
            updateSelectedItem={async (item) => onChange(await handleOnUpdateSelected(item))}
            placeholder={category ? "Replace category" : "Write a category"}
            renderOption={(option, state) => (
              <HStack
                p="4px"
                pl="8px"
                w="100%"
                borderRadius="8px"
                _hover={{ bg: "brandRedAlpha.400", cursor: "pointer" }}
                justifyContent={"space-between"}
                minH="32px"
              >
                {/* This is for testing purpose  */}
                <Text as="option">{option.name}</Text>
                {state.isHovered && (
                  <IconButton
                    minW="fit-content"
                    p="4px"
                    h={"fit-content"}
                    borderRadius={"5px"}
                    icon={<Icon w="16px" h="16px" as={TrashIcon} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCategoryToRemove(option);
                      onOpen();
                    }}
                  />
                )}
              </HStack>
            )}
          />
        )}
      />
      <RemoveCategoryModal isOpen={isOpen} onClose={onClose} handleOnDelete={handleOnDelete} />
    </>
  );
}

/**
 * @prop {function} onClose - Callback function to close the Modal
 * @prop {function} isOpen - Boolean to show/hide the Modal
 * @prop {function} handleOnDelete - Callback function to delete the category
 */
export function RemoveCategoryModal({ onClose, isOpen, handleOnDelete }) {
  return (
    <Modal onClose={onClose} size="md" isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete category?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>You cannot undo this action. Do you wish to continue?</Text>
        </ModalBody>
        <ModalFooter justifyContent={"space-between"} gap={"16px"}>
          <Button flex="1" variant="solid" onClick={onClose}>
            Cancel
          </Button>
          <Button flex="1" variant="primary" onClick={handleOnDelete}>
            Yes, delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

RemoveCategoryModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  handleOnDelete: PropTypes.func,
};
