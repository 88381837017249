import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { catalogSelector } from "@redux/webshops/catalog/catalogSlice";
import { Box, FormControl, HStack, Icon, NumberInput, NumberInputField, Text, Switch, VStack } from "@chakra-ui/react";
import { SubsectionTitle } from "../SubsectionTitle";
import { DatePickerComponent } from "../date-picker/DatePicker";
import { DropdownOption } from "../dropdown-option/DropdownOption";
import { ERRORS, validateDateRange, validateRecurrence } from "../utils";
import { useFormContext, Controller } from "react-hook-form";
import { ItemStatusSelector } from "../ItemStatusSelector";

import { useFlags } from "launchdarkly-react-client-sdk";
import {
  START_TIME_REGISTER_NAME,
  END_TIME_REGISTER_NAME,
  RECURRENCE_PERIOD,
  RECURRENCE_SERIES_PERIOD,
  END_PARAMETER,
  AVAILABILITY_TYPE,
} from "@src/consts/webshops/catalog";
import ErrorBoundary from "@components/ErrorBoundary";

import { TOOLTIPS } from "./utils";

import { ReactComponent as AlertTriangle } from "@assets/img/icons/common/alertTriangle.svg";
import PropTypes from "prop-types";
import moment from "moment";

export const Availability = ({ type = AVAILABILITY_TYPE.ITEM }) => {
  const { periodicOffers = false } = useFlags();
  const { selectedItem } = useSelector(catalogSelector);
  const [remainingText, setRemainingText] = useState("");
  const { control, formState, watch, setValue, setError, clearErrors } = useFormContext();
  const { errors } = formState;

  const { available, remainingQuantity, startTime, endTime, recurrenceInterval } = watch();

  const recurrenceOptions = type === AVAILABILITY_TYPE.ITEM ? RECURRENCE_PERIOD : RECURRENCE_SERIES_PERIOD;

  function startTimeChange(date) {
    // if the date is null or undefined, we should make the item unavailable
    if (!date) {
      setValue("available", false);
      setValue("recurrenceInterval", null);
    }
    // if the start date is today and the time is same or before the current time,
    // we should make the item available
    else if (moment(date).isSame(moment(), "day") && moment(date).isSameOrBefore(moment(), "hour")) {
      setValue("available", true);
    }
    // if the start date is in the future or in the same day but the time is after the current time,
    // we should make the item unavailable
    else if (moment(date).isAfter(moment(), "day") || moment(date).isAfter(moment(), "hour")) {
      setValue("available", false);
    }
  }

  function availableChange(isAvailable) {
    const now = moment();
    const endMoment = moment(endTime);

    // If the item is available, we should set the start date to today.
    // If the item is unavailable and the endTime expire,
    // and we change to available, end time should be cleared
    if (isAvailable) {
      setValue("startTime", new Date());
      if (endMoment.isBefore(now)) {
        setValue("endTime", "");
        setValue("recurrenceInterval", null);
      }

      // if we are editing an item and the remaining quantity is 0,
      // and also we set the item to available,
      // we should set the total quantity to unlimited
      if (selectedItem && remainingQuantity === 0) {
        setValue("remainingQuantity", "");
        setValue("totalQuantity", "");
      }
    } else {
      setValue("startTime", "");
      setValue("recurrenceInterval", null);
    }
  }

  useEffect(() => {
    // if are editing an item, this set the remaining text
    if (type === AVAILABILITY_TYPE.ITEM && selectedItem) {
      const { totalQuantity, remainingQuantity } = selectedItem;
      if (totalQuantity && remainingQuantity) {
        setRemainingText(`${remainingQuantity} / ${totalQuantity} remaining`);
      }
    }
  }, [type, selectedItem]);

  useEffect(() => {
    if (startTime && endTime) {
      const methods = { setError, clearErrors };
      validateDateRange(startTime, endTime, methods);
      if (periodicOffers) validateRecurrence(startTime, endTime, recurrenceInterval, methods);
    }
  }, [startTime, endTime, recurrenceInterval, periodicOffers, setError, clearErrors]);

  return (
    <VStack gap={"32px"}>
      <ErrorBoundary name={"series-overrides"} show={type === AVAILABILITY_TYPE.SERIES}>
        <VStack alignItems={"flex-start"} gap={0}>
          <SubsectionTitle>Overrides</SubsectionTitle>
          <Text size={"small"} color={"textSubtext.400"}>
            The availability you set here will override all conflicting availability settings within each item
          </Text>
        </VStack>
      </ErrorBoundary>
      {errors[ERRORS.RECURRENCE] && (
        <HStack
          w={"100%"}
          p={"8px 12px"}
          alignItems={"center"}
          bgColor={"brandRedAlpha.400"}
          borderRadius={"10px"}
          gap={"8px"}
        >
          <Icon as={AlertTriangle} width="16px" height="16px" color={"brandRed.400"} />
          <VStack alignItems={"flex-start"} gap={0}>
            <Text size="smallAlt" fontWeight={500} color={"brandRed.400"}>
              WARNING:
            </Text>
            <Text size="smallAlt" fontWeight={500} color={"textWhite.400"}>
              {errors[ERRORS.RECURRENCE].message}
            </Text>
          </VStack>
        </HStack>
      )}

      <FormControl>
        <SubsectionTitle tooltip={TOOLTIPS[type].STATUS}>Status</SubsectionTitle>
        <ErrorBoundary name={"item-available"} show={type === AVAILABILITY_TYPE.ITEM}>
          <Controller
            control={control}
            name={"available"}
            render={({ field }) => {
              const handleChange = (value) => {
                if (type === AVAILABILITY_TYPE.ITEM) availableChange(value);
                field.onChange(value);
              };
              return <ItemStatusSelector isAvailable={field.value} onChange={(value) => handleChange(value)} />;
            }}
          />
        </ErrorBoundary>

        <ErrorBoundary name={"series-available"} show={type === AVAILABILITY_TYPE.SERIES}>
          <Controller
            control={control}
            name={"available"}
            render={({ field: { onChange, value } }) => {
              const handleChange = (value) => {
                availableChange(value);
                onChange(value);
              };
              return (
                <HStack gap={"26px"}>
                  <Switch size="md" isChecked={value} onChange={(e) => handleChange(e.target.checked)} />
                  <Text>Active</Text>
                </HStack>
              );
            }}
          />
        </ErrorBoundary>
      </FormControl>

      <VStack w={"100%"} gap={"16px"}>
        <VStack alignItems={"start"} w={"full"} gap={0}>
          <SubsectionTitle tooltip={TOOLTIPS[type].START_TIME}>Start date</SubsectionTitle>
          <DatePickerComponent
            registerName={START_TIME_REGISTER_NAME}
            isAvailable={available}
            onDateChange={(date) => {
              if (type === AVAILABILITY_TYPE.ITEM) startTimeChange(date);
            }}
          />
        </VStack>

        <VStack alignItems={"start"} w={"full"} gap={0}>
          <SubsectionTitle tooltip={TOOLTIPS[type].END_TIME}>End date</SubsectionTitle>
          <DatePickerComponent
            registerName={END_TIME_REGISTER_NAME}
            isAvailable={available}
            hasError={errors?.invalidRangeDate}
          />
        </VStack>
      </VStack>

      {errors[ERRORS.DATE_RANGE] && (
        <Box w={"full"} as={"span"} alignItems={"start"} mt={"-15px"}>
          <Text size={"xs"} color={"brandRed.400"}>
            {errors[ERRORS.DATE_RANGE].message}
          </Text>
        </Box>
      )}

      <ErrorBoundary name={"recurrence-interval"} show={periodicOffers || type === AVAILABILITY_TYPE.SERIES}>
        <FormControl>
          <SubsectionTitle tooltip={TOOLTIPS[type].RECURRENCE}>Recurrence</SubsectionTitle>
          <Controller
            name="recurrenceInterval"
            control={control}
            render={({ field }) => {
              const selected = recurrenceOptions.find((option) => option.value === field.value);
              return (
                <DropdownOption
                  name={"recurrenceInterval"}
                  options={recurrenceOptions}
                  itemSelected={selected}
                  onChange={field.onChange}
                  isDisabled={!startTime}
                />
              );
            }}
          />
        </FormControl>
      </ErrorBoundary>

      <ErrorBoundary name={"item-quantities"} show={type === AVAILABILITY_TYPE.ITEM}>
        <FormControl>
          <SubsectionTitle tooltip={TOOLTIPS[type].TOTAL_QUANTITY}>Total Quantity</SubsectionTitle>
          <Controller
            name="totalQuantity"
            control={control}
            render={({ field }) => (
              <NumberInput
                {...field}
                onChange={(valueString, valueNumber) => {
                  if (isNaN(valueNumber)) {
                    field.onChange("");
                    setRemainingText("");
                  } else {
                    field.onChange(valueNumber);
                    setRemainingText(`${valueNumber} / ${valueNumber} remaining`);
                  }
                }}
              >
                <NumberInputField placeholder={field.value === "" ? "Unlimited" : ""} />
              </NumberInput>
            )}
          />
          <Text size={"mini"} mt={"4px"} letterSpacing={"0.24px"} color={"textSubtext.400"}>
            {remainingText}
          </Text>
        </FormControl>

        <FormControl>
          <SubsectionTitle tooltip={TOOLTIPS[type].QUANTITY}>Quantity per player</SubsectionTitle>
          <Controller
            name="quantityPerPlayer"
            control={control}
            render={({ field }) => (
              <NumberInput
                {...field}
                onChange={(valueString, valueNumber) => {
                  isNaN(valueNumber) ? field.onChange("") : field.onChange(valueNumber);
                }}
              >
                <NumberInputField placeholder={field.value === "" ? "Unlimited" : ""} />
              </NumberInput>
            )}
          />
        </FormControl>
      </ErrorBoundary>

      <ErrorBoundary name={"series-end-parameter-and-quantity"} show={type === AVAILABILITY_TYPE.SERIES}>
        <FormControl>
          <SubsectionTitle>End Parameters</SubsectionTitle>
          <Controller
            name="endParameter"
            control={control}
            render={({ field }) => {
              const selected = END_PARAMETER.find((option) => option.value === field.value) || END_PARAMETER[0];
              return (
                <DropdownOption
                  name={"endParameter"}
                  options={END_PARAMETER}
                  itemSelected={selected}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </FormControl>

        <VStack alignItems={"flex-start"} gap={0}>
          <SubsectionTitle tooltip={TOOLTIPS[type].TOTAL_QUANTITY}>Quantity</SubsectionTitle>
          <Text size={"small"} color={"textSubtext.400"}>
            All quantities are controlled at the individual item level
          </Text>
        </VStack>
      </ErrorBoundary>
    </VStack>
  );
};

Availability.propTypes = {
  type: PropTypes.string,
};
