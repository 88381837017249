import React from "react";
import {
  Box,
  Button,
  HStack,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  Text,
} from "@chakra-ui/react";

import { copy } from "@utils/general";
import { snakeToCamelCase } from "@utils/string";
import { ReactComponent as TooltipIcon } from "@assets/img/icons/common/tooltip.svg";

import PropTypes from "prop-types";
import { getBase64 } from ".";

export const FILE_TYPES = {
  MAIN: "MAIN",
  BACKGROUND: "BACKGROUND_IMAGE",
  FAVICON: "FAVICON_IMAGE",
  SOCIAL: "SOCIAL_IMAGE",
};

export const COLOR_TYPES = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  BACKGROUND: "BACKGROUND",
  TERTIARY: "TERTIARY",
  TITLE: "TITLE",
  TEXT: "TEXT",
};

export const getImage = (name = FILE_TYPES.MAIN, files = []) => {
  const fileSchema = {
    name: "",
    label: "",
    url: "",
  };
  try {
    // Get an image by the name property
    // ex: MAIN, BACKGROUND_IMAGE, FAVICON_IMAGE, SOCIAL_IMAGE
    if (!Array.isArray(files) || !files.length) return fileSchema;
    const file = files.find((file) => file.name === name);
    return file || fileSchema;
  } catch (error) {
    return fileSchema;
  }
};

export const generateFilePayloads = async ({ data = {}, fileObj = null }) => {
  const { type = "", files = [] } = data;
  if (!type) {
    console.log("Missing type for file payload");
    return {
      request: null,
      response: null,
    };
  }

  // File can be either a file object or a file URL
  // New images will be file objects, existing images will be URLs
  const { file = null, target = "", overlay = 0 } = fileObj;

  if (!file) {
    throw new Error("Missing file for file payload");
  }

  const requestForm = new FormData();
  requestForm.append("file", file);
  const dataKey = type === "GLOBAL" ? "metadata" : "data";
  const dataJSON = JSON.stringify({
    type,
    target,
    overlay,
  });

  requestForm.append(dataKey, new Blob([dataJSON], { type: "application/json" }));

  let url = file;
  let base64 = null;
  if (file instanceof File) {
    base64 = await getBase64(fileObj.file);
    url = URL.createObjectURL(fileObj.file);
  }

  // Update the file where the target matches either the target or the name
  let found = false;
  const updatedFiles = files.map((file) => {
    if (target === file.target || target === file.name) {
      found = true;
      return {
        url,
        base64,
        name: file.name || target,
        id: file.id,
        overlay,
      };
    }
    return file;
  });

  // If the file was not found, add it to the files array
  if (!found) {
    updatedFiles.push({
      url,
      base64,
      name: target,
      id: fileObj.id,
      overlay,
    });
  }

  return {
    request: requestForm,
    response: {
      ...data,
      files: updatedFiles,
    },
  };
};

export const generateFileUpdatePayloads = ({ data = {}, fileObj = {} }) => {
  if (!data || !fileObj || !data?.files?.length) {
    throw new Error("Missing data for file update");
  }

  const { type = "" } = data;
  const { target = "", overlay = null, file: url = "" } = fileObj;

  const request = {
    type,
    target,
  };
  if (typeof overlay === "number") {
    request.overlay = overlay;
  }

  const newFiles = data.files.map((file) => {
    if (file.name !== target) return file;
    return {
      ...file,
      ...fileObj,
      url,
    };
  });

  return copy({
    request,
    response: {
      ...data,
      files: newFiles,
    },
  });
};

export const generateFileRemovePayloads = ({ data = {}, fileObj = null }) => {
  const request = {
    type: data.type,
    target: fileObj.target,
  };

  // We need to remove that file from the sections "files" array
  const newFiles = data.files.filter((file) => {
    return !(file.id === fileObj.id || file.name === fileObj.target || file.name === fileObj.name);
  });

  return copy({
    request,
    response: {
      ...data,
      files: newFiles,
    },
  });
};

export const generateThemePayloads = ({ data = {}, themeObj = {} }) => {
  /*
    The request object here will look a lot different than the response object.
    The request will just be the color payload needed, and the response will
    be the entire section object with the new color applied.
  */
  const targetKey = snakeToCamelCase(themeObj.targetColor);
  const themeOverride = data.themeOverride || {};
  const request = {};
  // Add all the existing theme overrides that are valid
  Object.keys(themeOverride).forEach((key) => {
    if (themeOverride[key].color) {
      request[key] = {
        type: "COLOR",
        color: themeOverride[key].color,
      };
    }
  });
  // Add the new target color
  request[targetKey] = {
    type: "COLOR",
    color: themeObj.value,
  };

  return copy({
    request,
    response: {
      ...data,
      themeOverride: {
        ...data.themeOverride,
        [targetKey]: {
          ...themeObj,
          color: themeObj.value,
        },
      },
    },
  });
};

export const generateThemeResetPayloads = ({ data = {}, themeObj = {} }) => {
  // We really just need to nullify the theme override
  // There is no request body for this delete call
  return copy({
    response: {
      ...data,
      themeOverride: null,
    },
  });
};

export const getSectionTheme = (storefront = {}, data = {}, opts = {}) => {
  const globalTheme = storefront?.theme || {};
  const themeOverrides = data?.themeOverride || {};
  const { exclude = {} } = opts;
  const colors = Object.keys(COLOR_TYPES);
  const sectionTheme = {
    presets: [],
  };
  // Get the colors, checking for overrides
  colors.forEach((color, index) => {
    const colorKey = color.toLowerCase();
    const colorObj = globalTheme[colorKey] || {};
    // We only want the first 4 colors as presets
    if (index < 4) sectionTheme.presets.push(colorObj.color || "#FFFFFF");
    const overrideColor = themeOverrides?.[colorKey]?.color;
    if (overrideColor) {
      sectionTheme[colorKey] = overrideColor;
    } else if (exclude[color]) {
      sectionTheme[colorKey] = null;
    } else {
      sectionTheme[colorKey] = colorObj.color || "#000000";
    }
  });
  return sectionTheme;
};

export const PropertiesHeader = ({ title = "Properties", children }) => {
  return (
    <HStack width="100%" padding={"8px 12px"} borderBottom="var(--border-dark-500)" justifyContent={"space-between"}>
      {children || (
        <Text size={"16px"} lineHeight={"24px"} fontWeight={500} opacity={0.5}>
          {title}
        </Text>
      )}
    </HStack>
  );
};

PropertiesHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export const SectionWrapper = ({ title = "", onReset = null, icon, style = {}, children }) => {
  const iconContainer = icon ?? null;
  return (
    <Box py={"12px"} borderBottom="var(--border-dark-500)" {...style}>
      {title && (
        <HStack justifyContent={"space-between"} px={"12px"} pb={"8px"}>
          <HStack gap="4px">
            <Text fontWeight={600} fontSize={"14px"} color="textWhite.400">
              {title}
            </Text>

            {iconContainer}
          </HStack>

          {onReset && typeof onReset === "function" && (
            <Button
              size="sm"
              variant="ghost"
              minWidth="auto"
              minHeight="auto"
              height={"16px"}
              onClick={onReset}
              color="textWhite.400"
              bg={"transparent"}
              opacity={0.5}
              p={0}
            >
              Reset
            </Button>
          )}
        </HStack>
      )}
      {children}
    </Box>
  );
};

SectionWrapper.propTypes = {
  title: PropTypes.string,
  onReset: PropTypes.func,
  icon: PropTypes.node,
  style: PropTypes.object,
  children: PropTypes.node,
};

/**
 * @prop {node} children - Children components.
 * @prop {string} placement - Tooltip placement.
 * @prop {string} imageSrc - Image source.
 */
export const SectionWrapperTooltip = ({ children }) => {
  return (
    <Popover trigger={"hover"} placement={"top"}>
      <PopoverTrigger>
        <Icon as={TooltipIcon} w={"16px"} h={"16px"} ml={"5px"} />
      </PopoverTrigger>
      <PopoverContent maxW={"250px"}>
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

SectionWrapperTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};
