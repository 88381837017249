import React, { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import { Sidebar, SidebarLink } from "@sanlo/ui-components";
import { Link } from "react-router-dom";

import { Avatar } from "@chakra-ui/react";
import { trackGTMEvent } from "@utils/gtm";

import { ReactComponent as LogoutIcon } from "@assets/img/icons/pages/logout.svg";

import routes from "./routes";

const BodySmallPlusCSSInline = {
  fontFamily: "Inter, Helvetica, sans-serif",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "16px",
  letterSpacing: "0.01em",
  textAlign: "left",
};

const trackSidebarLink = (name) => {
  trackGTMEvent({
    event: "Sidebar Option Click",
    name,
  });
};

export const MainSidebar = withTheme(
  ({
    userName = "",
    location = {},
    flags = {},
    sidenavOpen = true,
    offers = [],
    onLogout = () => {},
    toggleSidenav = () => {},
    hidden = false,
  }) => {
    const [isOpen, setIsOpen] = useState(sidenavOpen);

    useEffect(() => {
      toggleSidenav(isOpen);
    }, [isOpen, toggleSidenav]);

    if (hidden) return null;

    return (
      <Sidebar
        zIndex="1"
        position="fixed"
        area="sidebar"
        data-test-id="sidebar"
        data-testid="sidebar"
        isOpen={isOpen}
        onToggle={() => {
          setIsOpen(!isOpen);
        }}
      >
        {routes.map((route) => {
          const {
            split = false,
            flag = "",
            name = "",
            path = "",
            icon = null,
            iconFilled = null,
            suffix = null,
            subroutes = [],
          } = route;

          const isFlagEnabled = flag ? flags[flag] : true;
          const isMatch = location.pathname === path;
          const isFuzzyMatch = location.pathname.includes(path);

          if (!isFlagEnabled) return <></>;

          if (route.name === "User") {
            return (
              <SidebarLink
                key={name}
                as={Link}
                to={path}
                icon={<Avatar size="xs" name={userName} />}
                isActive={isMatch}
                style={BodySmallPlusCSSInline}
              >
                {userName}
              </SidebarLink>
            );
          }
          return (
            <SidebarLink
              key={name}
              as={Link}
              to={path}
              icon={isFuzzyMatch ? iconFilled : icon}
              isActive={isFuzzyMatch}
              activePath={location.pathname}
              subroutes={subroutes}
              onClick={() => trackSidebarLink(route.name)}
              style={{
                ...BodySmallPlusCSSInline,
                marginTop: `${split ? "auto" : ""}`,
              }}
            >
              {name}
              {suffix && suffix}
            </SidebarLink>
          );
        })}

        <SidebarLink
          isActive={false}
          onClick={onLogout}
          icon={<LogoutIcon />}
          style={{
            ...BodySmallPlusCSSInline,
          }}
        >
          Log Out
        </SidebarLink>
      </Sidebar>
    );
  },
);
