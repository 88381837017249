import React, { isValidElement, useState } from "react";
import { Box, Text, chakra, shouldForwardProp } from "@chakra-ui/react";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";

const AnimatedBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const dropdownContentVariants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: -8 },
};

export function DropdownContent({ children, isOpen, handleSelectedItem, isMultiple, ...rest }) {
  return (
    <AnimatePresence mode="popLayout" initial="false">
      {isOpen && (
        <AnimatedBox
          key="dropdown-content"
          role="listbox"
          variants={dropdownContentVariants}
          initial={"closed"}
          animate={"open"}
          exit={"closed"}
          boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.16)"}
          maxH="156px"
          display={"flex"}
          flexDir={"column"}
          alignItems={"start"}
          bg="dark.600"
          p="8px"
          gap="4px"
          borderRadius={"10px"}
          mt={"8px"}
          pos={"absolute"}
          left={"0"}
          right={"0"}
          overflow={"auto"}
          {...rest}
        >
          {children}
        </AnimatedBox>
      )}
    </AnimatePresence>
  );
}

export function DropdownItem(props) {
  const { children, value, displayField, renderOption, selected, getOptionLabel, ...rest } = props;
  const [isHovered, setIsHovered] = useState(false);
  const state = { isHovered, selected };

  const renderOptionComponent = () => renderOption(value, state);

  if (renderOption && !isValidElement(renderOptionComponent())) {
    throw new Error("renderOption prop must be a valid React element");
  }

  if (renderOption) {
    return React.cloneElement(renderOptionComponent(), {
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => setIsHovered(false),
      ...rest,
    });
  }
  // Added this variant to don't break the pricingTier component
  if (getOptionLabel) {
    return (
      <Box
        as="option"
        aria-selected={selected}
        p="4px"
        pl="8px"
        w="100%"
        borderRadius="8px"
        _hover={{ bg: "brandRedAlpha.400", cursor: "pointer" }}
        fontWeight={"inherit"}
        minH={"initial"}
        {...rest}
      >
        <Text size={"small"}>{getOptionLabel ? getOptionLabel(value, state) : value}</Text>
      </Box>
    );
  }

  return (
    <Box
      as="option"
      aria-selected={selected}
      p="4px"
      pl="8px"
      w="100%"
      borderRadius="8px"
      _hover={{ bg: "brandRedAlpha.400", cursor: "pointer" }}
      fontWeight={"inherit"}
      minH={"initial"}
      {...rest}
    >
      {displayField ? value[displayField] : value}
    </Box>
  );
}
