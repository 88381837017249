export const PUBLISH = {
  completed: {
    message: "Project has been successfully published.",
    type: "success",
  },
  error: {
    message: "There was an error publishing the project.",
    type: "error",
  },
};

export const SETTINGS_IMAGES_TYPE = {
  FAVICON: "FAVICON",
  SOCIAL_SHARING_IMAGE: "SOCIAL_SHARING_IMAGE",
};

export const BUILDER_SECTION_TYPES = {
  GLOBAL: "GLOBAL",
  HEADER: "HEADER",
  FOOTER: "FOOTER",
  ITEMS: "ITEMS",
  FEATURED_ITEM: "FEATURED_ITEM",
  BANNER: "BANNER",
  LOGIN: "LOGIN",
  NAVIGATION: "NAVIGATION",
  DEEPLINK: "DEEPLINK",
};

export const BUILDER_SELECTABLE_SECTION_TYPES = [
  {
    type: BUILDER_SECTION_TYPES.NAVIGATION,
    label: "Navigation Bar",
    description: "Links for user to jump to each section you’ve added ",
    seedData: {},
  },
  {
    type: BUILDER_SECTION_TYPES.BANNER,
    label: "Banner",
    description: "A banner section to display an image",
    seedData: {},
  },
  {
    type: BUILDER_SECTION_TYPES.FEATURED_ITEM,
    label: "Featured",
    description: "Feature or highlight a single product",
    seedData: {
      title: "Featured Product",
      itemId: null,
    },
  },
  {
    type: BUILDER_SECTION_TYPES.ITEMS,
    label: "Catalog",
    description: "Display a catalog of products",
    seedData: {
      title: "Catalog",
      itemIds: [],
    },
  },
];

export const AUTH_METHODS = {
  PASSWORD: "PASSWORD",
  MANUAL: "MANUAL",
};
