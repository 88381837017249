import React, { useState, useLayoutEffect, cloneElement } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Icon, Modal, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";

import { AddStep } from "./AddStep";
import { EditStep } from "./EditStep";

import { AddIcon } from "@chakra-ui/icons";
import { ReactComponent as CrossIcon } from "@assets/img/icons/common/cross3.svg";

import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

const variants = {
  enters: (direction) => ({
    x: direction > 0 ? "100%" : "-100%",
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? "100%" : "-100%",
    opacity: 0,
  }),
};

const steps = [<AddStep key={"add-step"} />, <EditStep key={"edit-step"} />];

/**
 * @prop {Object|Array} selected - Object or Array of selected item/s.
 * @prop {Boolean} multiSelect - Allow multiple item selection.
 * @prop {Boolean} showFilter - Show filter input for step 1.
 * @prop {String} description - Description text for step 1.
 * @prop {String} buttonLabel - Button label for item selector.
 * @prop {Object} buttonStyles - Button style for item selector.
 * @prop {Object} catalog - Catalog data. Item and price tiers.
 * @prop {Function} onSelect - Callback function to select item/s.
 */
export const ItemSelector = ({
  selected,
  catalog = {},
  buttonLabel = "Add item",
  buttonStyles = {},
  description = "",
  multiSelect = false,
  showFilter = false,
  onSelect = () => {},
}) => {
  const [[step, direction], setStep] = useState(() => [0, 0]);
  const [selectedItem, setSelectedItem] = useState(multiSelect ? [] : {});
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data = {} } = catalog;
  const { items = [], tiers: priceTiersMap = {} } = data;

  const nextStep = (provider) => {
    if (step < steps.length - 1) {
      return setStep([step + 1, 1]);
    }
    onClose();
  };

  const prevStep = () => {
    if (step > 0) {
      return setStep([step - 1, -1]);
    }
    onClose();
  };

  useLayoutEffect(() => {
    if (isOpen && !isEmpty(selected)) {
      setSelectedItem(selected);

      if (multiSelect) {
        setStep([1, 1]);
      }
    }
  }, [isOpen, selected, multiSelect]);

  return (
    <>
      <Button
        leftIcon={(isEmpty(selected) || multiSelect) && <AddIcon h="12px" w="12px" />}
        iconSpacing="12px"
        onClick={() => {
          onOpen();
        }}
        {...buttonStyles}
      >
        {buttonLabel}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        onCloseComplete={() => {
          setStep([0, 0]);
        }}
      >
        <ModalOverlay />
        <ModalContent pos={"relative"} overflow={"hidden"} maxW={"695px"}>
          <AnimatePresence initial={false} custom={direction} mode="popLayout">
            <motion.div
              style={{ display: "flex", flexDirection: "column", flex: 1, position: "relative" }}
              variants={variants}
              transition={{ x: { type: "spring", stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
              initial="enters"
              animate="center"
              custom={direction}
              exit="exit"
              key={`multi_step_${step}`}
              layout
            >
              <Icon
                as={CrossIcon}
                onClick={onClose}
                w="24px"
                h="24px"
                cursor="pointer"
                color={"textSubtext.400"}
                pos={"absolute"}
                right={"-8px"}
              />
              {isOpen &&
                cloneElement(steps[step], {
                  items,
                  selectedItem,
                  priceTiersMap,
                  description,
                  multiSelect,
                  showFilter,
                  handleNextStep: nextStep,
                  handlePrevStep: prevStep,
                  onSelect,
                  setSelectedItem,
                  onClose,
                })}
            </motion.div>
          </AnimatePresence>
        </ModalContent>
      </Modal>
    </>
  );
};

ItemSelector.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  catalog: PropTypes.object,
  buttonLabel: PropTypes.string,
  buttonStyles: PropTypes.object,
  description: PropTypes.string,
  multiSelect: PropTypes.bool,
  showFilter: PropTypes.bool,
  onSelect: PropTypes.func,
};
