import {
  Accordion,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Icon,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  catalogSelector,
  catalogThunks,
  setDraftItemProps,
  resetDeepLinkAvailable,
} from "@redux/webshops/catalog/catalogSlice";

import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { ConfirmationModal } from "@common/modals/confirmation-modal";
import { Section, ItemSummary, Availability, Segmentation } from "@components/catalog";
import { BadgesTooltip } from "@components/catalog/tooltip";
import { ReactComponent as TrashIcon } from "src/assets/img/icons/common/trash-icon.svg";
import { General, Badges } from "./components";
import { createFormData, getFormattedForm } from "./utils";
import { StyledLoader } from "@common/inputs/SanloButton/SanloButton";
import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";

/**
 * @prop {boolean} isOpen - Drawer visibility state.
 * @prop {function} onClose - Callback function to close the drawer.
 */

export function AddItem({ isOpen, onClose }) {
  let { periodicOffers = false } = useFlags();
  const { defaultItem, selectedItem, createItem, updateItem } = useSelector(catalogSelector);
  const dispatch = useDispatch();
  const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const isSubmitting = createItem.requestStatus.pending || updateItem.requestStatus.pending;

  const methods = useForm();
  const { handleSubmit, reset, watch } = methods;

  useEffect(() => {
    if (selectedItem) {
      reset(getFormattedForm(selectedItem));
    } else {
      reset(defaultItem);
    }
  }, [defaultItem, selectedItem, reset]);

  function handleRemoveItem() {
    dispatch(catalogThunks.deleteItem(selectedItem.id));
    onCloseModal();
    onClose();
  }

  async function onSubmit(data) {
    if (selectedItem) {
      const { image, ...rest } = data;
      await dispatch(catalogThunks.updateItem(rest)).unwrap();
    } else {
      const formData = createFormData(data);
      if (data) {
        await dispatch(catalogThunks.createItem(formData)).unwrap();
      }
    }
    cleanAndClose();
  }

  function cleanAndClose() {
    reset(defaultItem);
    dispatch(setDraftItemProps({ badge: null, category: null }));
    dispatch(resetDeepLinkAvailable());
    onClose();
  }

  function onCloseComplete() {
    if (selectedItem) {
      dispatch(setDraftItemProps({ badge: null, category: null }));
    }
    onClose();
  }

  return (
    <>
      <FormProvider {...methods}>
        <Drawer isOpen={isOpen} placement={"right"} onClose={onClose} onCloseComplete={onCloseComplete}>
          <DrawerContent p="32px" pb="16px" maxW="460px" data-test-id={"catalog-add-item-drawer"}>
            <DrawerHeader userSelect={"none"}>
              {selectedItem ? "Edit item" : "New item"}
              <DrawerCloseButton right="32px" w={"24px"} h="24px" />
            </DrawerHeader>

            <DrawerBody pt={"8px"}>
              <form id="item-creation-form" onSubmit={handleSubmit(onSubmit)}>
                <Accordion variant={"catalog"} allowMultiple defaultIndex={[0]}>
                  <Section name={"General"}>
                    <General />
                  </Section>
                  <Section name={"Availability"}>
                    <Availability />
                  </Section>
                  <Section name={"Segmentation"}>
                    <Segmentation />
                  </Section>
                  <Section name={"Badges"} tooltip={<BadgesTooltip />}>
                    <Badges />
                  </Section>
                </Accordion>
              </form>
            </DrawerBody>

            <DrawerFooter>
              <VStack w={"100%"} mb={"10px"} gap={"24px"}>
                {periodicOffers && <ItemSummary watch={watch} />}
                <Flex gap="24px" w="full">
                  <Button
                    isDisabled={isSubmitting}
                    onClick={() => {
                      cleanAndClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    flex="1"
                    variant="primary"
                    type="submit"
                    form="item-creation-form"
                    isLoading={isSubmitting}
                    spinner={<StyledLoader />}
                    data-test-id={"catalog-item-submit"}
                  >
                    {selectedItem ? "Save changes" : "Add item"}
                  </Button>
                </Flex>
                {selectedItem && (
                  <Button
                    onClick={onOpenModal}
                    flex="1"
                    variant="tertiary"
                    color="brandRed.400"
                    leftIcon={<Icon as={TrashIcon} />}
                    data-test-id={"catalog-remove-item-submit"}
                    isDisabled={isSubmitting}
                  >
                    Remove item
                  </Button>
                )}
              </VStack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </FormProvider>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onAccept={handleRemoveItem}
        title={"Delete item?"}
        acceptText={"Yes, delete"}
        cancelText={"Cancel"}
      >
        <Text size={"smallAlt"} letterSpacing={"0.14px"}>
          You cannot undo this action. Do you wish to continue?
        </Text>
      </ConfirmationModal>
    </>
  );
}

AddItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
