import { copy } from "@utils/general";

import { BUILDER_SECTION_TYPES } from "@src/consts/webshops/builder";
export const SECTION_TYPE = BUILDER_SECTION_TYPES.FEATURED_ITEM;

export const generatePayloads = ({ data, updates }) => {
  const combinedSectionData = {
    type: SECTION_TYPE,
    ...copy(data),
    ...copy(updates),
  };

  // If something like the title changed, the current itemId
  // is still needed for the request, so we have to copy it from the item
  if (combinedSectionData.item && !combinedSectionData.itemId) {
    combinedSectionData.itemId = combinedSectionData.item.id;
  }

  // Other info in the section will cause errors
  // like "id" and "items" so we need to remove them
  const requiredDefaults = {
    type: SECTION_TYPE,
    order: 0,
    title: "",
    itemId: "",
  };

  const request = {};
  Object.keys(requiredDefaults).forEach((key) => {
    if (Object.hasOwn(combinedSectionData, key)) {
      request[key] = combinedSectionData[key];
    } else {
      request[key] = requiredDefaults[key];
    }
  });

  return copy({
    request,
    response: combinedSectionData,
  });
};
