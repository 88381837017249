import React from "react";

import { BodySmallAlt } from "@styled";
import PrimaryButton from "@common/buttons/primary-button/PrimaryButton";

import symbols from "@utils/symbols";

const NoLiveAppsBodyState = ({ selectedOption }) => {
  const openEmailClient = () => {
    window.location.href = "mailto:support@sanlo.io";
  };

  return (
    <div className="dr-message">
      {symbols.Eyes()}
      <BodySmallAlt className="dr-message-text">
        Looks like you currently have no apps live on {selectedOption.name}. Daily revenue will show here for any live
        apps. Let us know if this doesn't look right.
      </BodySmallAlt>
      <PrimaryButton label="Contact Us" className="dr-message-button" onClick={openEmailClient} />
    </div>
  );
};

export default NoLiveAppsBodyState;
