import React from "react";

import { Link } from "@components/common/link";
import { SanloTooltip } from "@components/common/tooltips/sanlo-tooltip/SanloTooltip";
import Support from "@components/support/Support";
import { currencyFormat } from "@utils/currency";
import symbols from "@utils/symbols";
import { BodySmall14, BodySmallAlt, BodySmallAltPlus } from "@styled";
import { openBankingSection } from "../OpenBankingSectionForCopy";
import { useTheme } from "styled-components";
import { Flex, Text } from "@chakra-ui/react";
import { ONBOARDING_ACTIONS, useTrackHover } from "@utils/gtm";

export const paymentProviderLink = (
  <Link className="tooltip-link" href="https://gocardless.com/en-us/" isExternalLink>
    payment provider
  </Link>
);
export const currencyLink = (
  <Link className="tooltip-link" href="https://wise.com/gb/currency-converter/" isExternalLink>
    Wise
  </Link>
);

const AdNetworksPrimaryDescription = () => {
  const theme = useTheme();
  const [addTracking, removeTracking] = useTrackHover();
  const styles = { color: theme.textEmphasis };
  return (
    <>
      <BodySmallAlt style={{ cursor: "default" }}>
        We look at your{" "}
        <BodySmallAltPlus
          style={styles}
          id="product-analytics-tootltip"
          onMouseEnter={() => {
            addTracking({
              event: ONBOARDING_ACTIONS.CONNECT_SOURCE_PRODUCT_ANALYTIC_DATA,
            });
          }}
          onMouseLeave={() => {
            removeTracking();
          }}
        >
          product analytics data
        </BodySmallAltPlus>{" "}
        to better understand your game or app's performance, identify areas of growth, and provide personalized
        financing offers for you.
      </BodySmallAlt>
      <SanloTooltip
        width={260}
        target={"product-analytics-tootltip"}
        text={
          <BodySmallAlt>
            <BodySmallAltPlus style={styles}>We look at apps’ metrics</BodySmallAltPlus> like daily active users (DAU),
            retention, cost per install (CPI), average revenue per user (ARPU), and average revenue per daily active
            user (ARPDAU) <BodySmallAltPlus style={styles}>to determine your growth capital offer.</BodySmallAltPlus>
          </BodySmallAlt>
        }
        placement={"top"}
      />
    </>
  );
};

const generalCopies = {
  exact: {
    ad_networks: "Ad networks",
    confirm_uploading: "Confirm uploading",
    confirm_upload: "Confirm upload",
    non_whitelisted_email: "non_whitelisted_email",
    banking: "Banking",
    product_analytics: "Product analytics",
    platforms: "Platforms",
    ad_networks_and_user_acq_spend: "Ad networks and UA spend",
    almost_done: "Almost done",
    search_engine: "Search engine",
    social_media: "Social media",
    read_an_article: "Read an article",
    referral: "Referral",
    word_of_mouth: "Word of mouth",
    other: "Other",
    upload_statements: "Upload statements",
    cant_find_your_bank: "Can't find your bank?",
    total_earnings: "Total earnings",
    earnings_period: "Earnings period",
    repayment_amount: "Repayment amount",
    est_repayment_amount: "Est. repayment amount",
    exchange_rate: "Exchange rate",
    est_exchange_rate: "Est. exchange rate",
    est_repayment_date: "Est. repayment date",
    remaining_balance: `${symbols.moneyBag} Remaining balance`,
    wire_fee: "Wire fee",
    upload: "Upload",
    documents: "Documents",
    continue_to_dashboard: "Continue to dashboard",
    continue: "Continue",
    continue_to_sanlo_dashboard: "Continue to Sanlo dashboard",
    get_financing_in_minutes: "Get financing in minutes.",
    learn_more: "Learn more",
    sync_your_data: "Sync your data",
    review_your_offer: "Review your offer",
    get_funded: "Get funded",
    get_started: "Get started",
    already_have_an_account: "Already have an account?",
    log_in: "Log in",
    transparent_pricing: "Transparent pricing",
    fast_capital: "Fast capital",
    growth_acceleration: "Growth acceleration",
    integration_bank_text:
      "Bank data helps calculate your estimated runway and burn rate. Sanlo uses codat to securely integrate with your bank.",
  },
  phrase: {
    ad_networks_accordion_desc:
      "Unlocked revenue tracking of your Ad networks by integrating them with your Sanlo account.",
    // Daily Revenue Chart
    no_platform_selected: "Please, select a platform to see the data",
    no_game_selected: "Please select a game to see the data",

    // Integration lack of perimission text
    needs_attention: "Something went wrong with this connection, please reconnect.",
    needs_attention_label: `${symbols.stopwatch} We'll be in touch shortly...`,
    needs_attention_clarification: `${symbols.drums} It looks like we don't have access to some data. We'll reach out shortly with next steps.`,
    // File upload related text
    upload_size_limit: "File size exceeds the limit. Max size for file is 10MB.",
    upload_server_error:
      "Looks like file's not been uploaded. Please try re-uploading files or contact us at support@sanlo.io.",
    upload_unsupported_format: "Unsupported format. You can not upload video files.",
    invalid_file_extension: ({ fileName }) =>
      `File ${fileName} is invalid. Files’ format should be: pdf, ms word docs, csv, png, jpeg, x-ofx.`,
    upload_drop_files: "Upload and manage your files here",
    statements_received:
      "We received your bank statements. We'll get in touch in case any additional information is needed.",
    drop_statements_here: (
      <span>
        Please upload at least <span className="highlighted-text">6 months of bank statements</span>.<br />
        To get your offers sooner, we strongly recommend uploading 12 months of bank statements (preferably in English).
      </span>
    ),
    drop_statements_file_size: "100 MB maximum files size.",
    documents_received: ({ fileLength, date }) => `We received ${fileLength} ${
      fileLength > 1 ? "files" : "file"
    } from you on ${date}.
    We’ll get in touch shortly.`,
    statements_approved: "Thank you! Your bank statements are successfully approved.",

    // Platform specific text (pulling data)
    platform_suggestion_confirmation_message:
      "We've received your message, and will be in touch shortly with instructions.",

    // Connected integration status messages
    cism_keep_up: "Keep up the great work! Connect all required platforms to receive growth capital sooner.",
    cism_well_done:
      "Well done! You've now connected all the required platforms. Wait when the connections are established, we are on it. Stay tuned!",
    bank_provided_info_title: "Have you provided your full business banking information?",
    bank_provided_info_text: `To determine what you’re eligible for, it’s important we get a complete understanding of your business finances. To prevent delays, please ensure you’ve done the below ${symbols.pointDown}.`,

    // Onboarding Primary Flow
    banking_accordion_desc: ({ fromDashboard }) => (
      <>
        <BodySmall14>
          Banking data helps us evaluate metrics like runway and burn rate. We use plaid to securely sync your banking
          data.
        </BodySmall14>
        {!fromDashboard && (
          <BodySmall14 className="mt-16">
            *Please connect or provide statements for all bank accounts associated with your business.
          </BodySmall14>
        )}
      </>
    ),
    pa_accordion_desc_primary: <AdNetworksPrimaryDescription />,
    pa_accordion_note_primary: "*Connecting a marketing analytics data source is highly recommended.",

    // Onboarding Secondary Flow
    iap_accordion_desc:
      "Stay up to date on your most recent earnings and get visibility on upcoming payments from IAP platforms.",
    pa_accordion_desc_secondary: "Unlock tracking ad networks revenue and upcoming payments in one place.",
    pa_accordion_note_secondary:
      "Connect what is applicable to your company. Just so you know, appsFlyer integration also unlocks the user acquisition spend feature for additional insights.",

    you_are_a_few_steps: "You are a few steps away from growth capital!",
    onboarding_primary_inprogress_desc: (
      <>
        <BodySmall14 className="mb-16">
          You're so close to getting funded! Connect your bank account and analytics platform, and then we're all set to
          get you funded. {symbols.pointRight}
        </BodySmall14>
        <BodySmall14 className="mt-16">
          {symbols.drums} Please{" "}
          <button
            onClick={() => openBankingSection()}
            className="support-link-red"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
          >
            connect or provide statements
          </button>{" "}
          for all bank accounts associated with your business to get the best offer you are eligible for.
        </BodySmall14>
      </>
    ),
    onboarding_primary_completed_title: `${symbols.clappingHands} Well done! You connected all required platforms.`,
    onboarding_primary_completed_desc: (
      <>
        <BodySmall14>
          It may take up to 48 hours to validate credentials and establish the connections for all platforms. Hold tight
          and once it's done, we'll get back to you once the connections are established.
        </BodySmall14>
        <BodySmall14 className="mt-16">
          {symbols.drums} Meanwhile, please{" "}
          <button
            onClick={() => openBankingSection()}
            className="support-link-red"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
          >
            connect or provide statements
          </button>{" "}
          for all bank accounts associated with your business to get the best offer you are eligible for.
        </BodySmall14>
      </>
    ),
    onboarding_primary_connection_success_desc: (
      <Flex flexDirection={"column"} justifyContent={"space-between"}>
        <Text size={"small"} color={"textWhite.400"} letterSpacing={"0.01em"} mb={"16px"}>
          You've successfully connected all platforms and requested growth capital. We'll be in touch within 48 hours.
          Thank you for your patience!
        </Text>
        <Text size={"small"} color={"textWhite.400"} letterSpacing={"0.01em"}>
          {symbols.drums} Make sure you've linked or provided statements for all bank accounts associated with your
          business.
        </Text>
        <Text size={"small"} color={"textWhite.400"} letterSpacing={"0.01em"}>
          Have any questions?{" "}
          <Support
            text={
              <Text size={"regular"} color={"textWhite.400"} fontWeight={"500"}>
                Contact us
              </Text>
            }
          />
        </Text>
      </Flex>
    ),

    onboarding_secondary_notconnected_title: "You are one step away from setting up Sanlo analytics!",
    onboarding_secondary_notconnected_desc: `Don't give up, you're just one step away from tracking your revenue and upcoming payments. Connect applicable platforms in order to get the most out of Sanlo! ${symbols.pointRight}`,
    onboarding_secondary_connecting_title: `${symbols.clappingHands} Well done! Establishing connections...`,
    onboarding_secondary_connecting_desc:
      "It may take up to 24 hours to validate all credentials and establish the connection. Hold tight and once it's done, we'll get back to you once the connections are established.",
    onboarding_secondary_connected_title: `${symbols.pizza} Congrats! You unlocked daily revenue`,
    onboarding_secondary_connected_desc: "Start tracking all revenue and upcoming payments below.",

    remaining_balance_hint:
      "The remaining balance is calculated as total capital you received from Sanlo plus one-time capital fee minus all completed and upcoming repayments.",
    wire_fee_hint:
      "We cover certain fees, such as wire transfer fees. In those cases, we deduct those fees from your outstanding balance to be repaid to us so you don't have to pay for it.",

    est_repayment_date_hint: ({ name = "" }) => {
      return `We collect repayment the day after you receive earnings from ${name}.`;
    },
    advance_title_tooltip_text: ({ cryptoAsRepaymentSource = null, cryptoCurrency = "" }) =>
      cryptoAsRepaymentSource
        ? `Your repayment progress is shown in USD only. Each ${cryptoCurrency} is worth $1.00, and is always redeemable on a 1:1 basis for US dollars. For more details visit here.`
        : "Total growth capital received including one-time fees.",

    exchange_disclosure_pending: ({ cryptoAsRepaymentSource = null, cryptoCurrency = "" }) =>
      cryptoAsRepaymentSource ? (
        `We collect repayments in ${cryptoCurrency} currency and use exchange rate as of the date we process repayment amount. Each ${cryptoCurrency} is worth $1.00, and is always redeemable on a 1:1 basis for US dollars. For more details visit here.`
      ) : (
        <span>We use exchange rates from {currencyLink} to estimate upcoming repayment in USD.</span>
      ),

    sanlo_makes_a_decision: "Sanlo makes a decision based on your data so you'll get offers personalized to you.",
    choose_between_revenue_and_fixed:
      "Choose between revenue-based and fixed-term financing with a one-time flat fee that shows your full cost upfront.",
    get_the_funding_you_need:
      "Get the funding you need - fast - so you can focus on building awesome games and apps that people love.",
    get_more_funding_as_you_scale:
      "Get more funding as you scale, as soon as you need it. We'll notify you when you unlock new offers, no need to re-apply.",

    //Bank balance details
    bb_not_included: "• Not included in the current bank balance",
    bb_daily_balance: "Your daily bank balance in the bank accounts only over the last 3 months",
    bb_total_info: ({ currency = "" }) =>
      `This amount is total money you have in your ${currency} bank accounts. It excludes negative credit card’s balance.`,
    bb_manage_connections: ({ goToIntegrationsPage = undefined }) => (
      <>
        You can manage all your banking connections on the{" "}
        <span onClick={goToIntegrationsPage} className="label-button">
          Integrations
        </span>{" "}
        page.
      </>
    ),

    // Growth capital offers
    offer_title: "Your growth capital",
    active_offer: ({ onSetTab }) => (
      <>
        {" "}
        You already have{" "}
        <span onClick={onSetTab} className="learn-more">
          active capital
        </span>
        , you can apply for a new capital in a few weeks. If you have any questions, please{" "}
        {<Support text="contact us" red />}.
      </>
    ),
    last_step_header: `${symbols.stopwatch} Last steps before you receive your money.`,
    last_step_list_0:
      "We'll email you instructions to complete basic compliance identity verifications in the next 24 business hours.",
    last_step_list_1: "In the next 24 business hours, we’ll send you identity-check instructions.",
    last_step_list_2: "Once you complete the verification process, you’ll see here a final decision.",
    last_step_list_3: "You'll see a final decision here once the verification process is completed.",
    last_step_footer: (
      <>
        Have any questions about how it works?{" "}
        <Link
          className="learn-more tag-sanlo-capital-offers-learn-more-post-acceptance"
          href="https://www.sanlo.io/faq"
          isExternalLink
        >
          Learn More
        </Link>
      </>
    ),
    rejected_header: `${symbols.drum} We can’t offer you growth capital just yet.`,
    rejected_list_1:
      "We are not able to offer growth capital to you today. We’ve sent you an email with further details.",
    rejected_list_2: ({ onTrackAdvance }) => (
      <>
        However, you can still{" "}
        <span onClick={onTrackAdvance} className="learn-more">
          track your revenue
        </span>{" "}
        and upcoming payments.
      </>
    ),
    rejected_footer: (
      <>
        Please <Support text="get in touch with us" red /> if you have any questions.
      </>
    ),
    revenue_highest_amount_tooltip:
      "This is the amount you selected for revenue-based financing. You may adjust this amount in offer details.",
    revenue_blur_text: (
      <>
        We are not able to offer a revenue-based capital to you today. If you have any questions, please{" "}
        {
          <Support
            className="tag-sanlo-capital-offers-contact-us-unable-to-offer-revenue-based"
            text="contact us"
            red
          />
        }
      </>
    ),
    fixed_highest_amount_tooltip:
      "This is the amount you selected for fixed-term-based financing. You may adjust this amount in offer details.",
    fixed_blur_text: (
      <>
        We are not able to offer a fixed term-based capital to you today. If you have any questions, please{" "}
        {<Support className="tag-sanlo-capital-offers-contact-us-unable-to-offer-fixed-term" text="contact us" red />}.
      </>
    ),
    capital_expired: "Your growth capital offer has expired",
    offer_ready: "Congrats, your capital offer is ready!",
    offer_expires_in: "Capital offer expires in 5 weekdays if you don’t accept one of the offered options.",
    offer_will_expire: "Offers will expire if not accepted within 5 business days.",
    offer_has_expired: "Your capital offer has been expired and no longer available. You can simply re-apply ",
    created_personalized_offer:
      "We created your personalized capital offer so you can select the option that fits you the best.",
    for_a_new_offer: `for a new capital to get most accurate numbers. ${symbols.pointDown}`,
    have_full_flexibility: "You have full flexibility and may easily adjust the amount below.",
    have_any_question: (
      <>
        Have any questions? <Support red text="Contact us" />
      </>
    ),
    ques_how_it_works: (
      <>
        Have any questions about how it works?{" "}
        <Link
          className="learn-more tag-sanlo-capital-offers-learn-more-pre-selection"
          href="https://www.sanlo.io/faq"
          isExternalLink
        >
          Learn more
        </Link>
      </>
    ),
    max_amt_tooltip: "This is the highest amount we can offer you at this time.",
    revenue_legend: "Example of total monthly revenue for your business.",
    fixed_legend: "Example of your monthly repayments, based on your revenue and the selected offer terms.",
    revenue_repayment_text:
      "Repayments are paid as percentages of future revenues. The numbers in this chart are examples - your monthly repayment amount will be a percentage of your actual revenue.",
    fixed_repayment_text:
      "Monthly repayment amounts are fixed, regardless of the revenue you generate. The numbers in this chart are examples - your monthly repayment amount will be based on your final terms.",
    one_time_tooltip:
      "This is a flat fee that is included in your total repayment amount. We don’t charge any other fees like interest, royalties, points, or warrants.",
    repayment_rate_tooltip:
      "This is a repayment cap per every 28 calendar days. We automatically collect repayments from your linked bank account the day after you receive payouts from the app store.",
    revenue_offer_details:
      "Revenue-based is a great option for business investments with predictable returns on investment, like acquiring customers or releasing new features.",
    fixed_offer_details:
      "With a fixed term-based option repayments aren’t tied to revenue, and are repaid as stated in the payment schedule regardless of the revenue you generate.",
    active_offer_with_amount: ({ amount }) => (
      <>
        You've received your {currencyFormat(amount)} advance and it has been disbursed into your bank <br /> account.
        Stay on top of your money!
      </>
    ),
    callout_header_1: (
      <>
        {" "}
        Complete the <span className="text-bold text-underline">two steps below</span> to connect your google play.
        You'll need to have admin privileges to continue with this process.
      </>
    ),
    callout_header_2: `${symbols.lightBulb} We recommend that you create a read-only sub-user in your google play developer console dedicated exclusively to your Sanlo account.`,
    offer_summary: `Offer's summary`,
    one_time_fee: "One-time fee",
    repayment: "Repayment",
    no_of_repayments: "Number of repayments",
    not_seeing: (
      <>
        {" "}
        Not seeing what you’re looking for?{" "}
        <Support className="tag-sanlo-contact-us-capital-offers" red text="Contact us" />
      </>
    ),
    max_amount: "Max amount",
    reapply_for_capital: "Re-apply for capital",
    view_details: "View details",
    not_allowed_to_create_request: (
      <>
        You are currently not eligible to request capital. Please <Support red text="contact us" /> for more
        information.
      </>
    ),
    already_requested: "You've already requested capital. We'll be in touch shortly.",
    offer_active_request: (
      <>
        Congrats! You've requested growth capital. You'll see your <br /> personalized offer here once we've processed
        your application.
      </>
    ),
    active_tab_active_request: (
      <>
        Congrats! You've requested growth capital. You'll see your <br /> personalized offer in the Offer tab once we've
        processed your application.
      </>
    ),
  },
  labels: {},
  // wrapped: {
  //   we_use_exchange_rates: (
  //     <span>
  //       We use exchange rates from {currencyLink}. The rate we apply is as of the date the funds are disbursed to
  //       Sanlo's bank account.
  //     </span>
  //   ),
  // },

  step_guide_links: {
    adjust: {
      adjust_step_subscription: "https://www.adjust.com/pricing/",
      adjust_step_here: "https://help.adjust.com/en/article/permission-dashboard#getting-started",
      adjust_step_1_adjust_account: "https://dash.adjust.com/account/data",
      adjust_step_1_api_token:
        "https://help.adjust.com/en/article/automation-api-authentication#how-to-find-your-adjust-api-token",
      adjust_step_2_apps: "https://dash.adjust.com/#/",
      adjust_step_2_app_token: "https://help.adjust.com/en/article/app-settings#view-your-app-token",
    },
  },
};

// Labels are used to create a reference a button can always use and the text
// it references can be changed here easily.
generalCopies.labels = {
  ...generalCopies.labels,

  onboarding_primary_inprogress_title: generalCopies.phrase.you_are_a_few_steps,
};

export default generalCopies;
