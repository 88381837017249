import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  baseStyle: {
    fontFamily: "body",
    fontWeight: 500,
    borderRadius: "0.625rem",
  },
  sizes: {
    sm: {
      "fontSize": "sm",
      "h": "16px",
      "minW": "16px",
      "borderRadius": "sm",
      "& svg": {
        display: "block",
      },
    },
    md: {
      fontSize: "sm",
    },
    lg: {
      height: "48px",
      fontSize: "md",
    },
    jumbo: {
      height: "64px",
      fontSize: "md",
    },
  },
  variants: {
    primary: {
      background: "brandRed.400",
      color: "dark.900",
      _hover: {
        // Opted to add the color token here since we need a better color definition so we can set all on the sanlo-ui-components
        background: "#FF4264",
        _disabled: {
          bg: "#FF4264",
        },
      },
      _disabled: {
        background: "#FF4264",
        _hover: {
          bg: "#FF4264",
        },
      },
    },
    solid: {
      background: "dark.400",
      _hover: {
        background: "dark.300",
      },
    },
    tertiary: {
      background: "transparent",
      color: "brandRed.400",
      _hover: {
        // Opted to add the color token here since we need a better color definition so we can set all on the sanlo-ui-components
        background: "#transparent",
        _disabled: {
          bg: "transparent",
        },
      },
      _disabled: {
        color: "#FF4264",
      },
    },
  },
  defaultProps: {
    colorScheme: "dark",
  },
});
