import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HStack, Link, Text, VStack } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import { TagInput } from "@components/common/tag-input/TagInput";
import ErrorBoundary from "@components/ErrorBoundary";

import { segmentsSelector } from "@redux/webshops/associations/associationsSlice";
import { catalogSelector, setDraftItemProps } from "@redux/webshops/catalog/catalogSlice";

export const Segmentation = () => {
  const dispatch = useDispatch();
  const segments = useSelector(segmentsSelector);
  const { list } = segments;
  const {
    selectedItem,
    draftItem: { playerSegmentIds },
  } = useSelector(catalogSelector);
  const { control } = useFormContext();

  useEffect(() => {
    if (selectedItem) {
      dispatch(setDraftItemProps({ playerSegmentIds: selectedItem.playerSegmentIds }));
    }
  }, [selectedItem, dispatch]);

  function handleOnChange(event, newValue, prevValue) {
    if (!newValue) {
      dispatch(setDraftItemProps({ playerSegmentIds: [] }));
      return [];
    }
    if (Array.isArray(newValue)) {
      dispatch(setDraftItemProps({ playerSegmentIds: newValue.map((segment) => segment.segmentId) }));
      return newValue.map((segment) => segment.segmentId);
    }
  }

  return (
    <ErrorBoundary
      name="item-segmentation"
      show={list.length}
      showAltUI={!list.length}
      altUI={
        <Text fontSize="md" color="gray.500" lineHeight="1.5">
          No segments have been created yet. Learn how to create segments{" "}
          <Link href="https://developer.sanlo.io/docs/segmented-offers" color="brandRed.400">
            here
          </Link>
          .
        </Text>
      }
    >
      <VStack w={"100%"} gap={"10px"}>
        <Controller
          name={"playerSegmentIds"}
          control={control}
          render={({ field: { onChange } }) => (
            <TagInput
              id="segments"
              data-test-id="tag-input-segments"
              isMultiple
              readOnly
              onChange={async (event, newValue, prevValue) => {
                onChange(handleOnChange(event, newValue, prevValue));
              }}
              value={playerSegmentIds.map((id) => list.find((segment) => segment.segmentId === id))}
              options={list.filter((segment) => !playerSegmentIds.includes(segment.segmentId))}
              displayField={"name"}
              placeholder={"Add segmentation"}
              renderOption={(option, state) => (
                <HStack
                  p="4px"
                  pl="8px"
                  w="100%"
                  borderRadius="8px"
                  _hover={{ bg: "brandRedAlpha.400", cursor: "pointer" }}
                  justifyContent={"space-between"}
                  minH="32px"
                >
                  <Text as="option">{option.name}</Text>
                </HStack>
              )}
            />
          )}
        />
        {!list.filter((segment) => !playerSegmentIds.includes(segment.segmentId)).length && (
          <Text fontSize="sm" color="gray.500">
            All segments have been added
          </Text>
        )}
      </VStack>
    </ErrorBoundary>
  );
};
