export const AVAILABILITY_TYPE = {
  ITEM: "ITEM",
  SERIES: "SERIES",
};

export const START_TIME_REGISTER_NAME = "startTime";
export const END_TIME_REGISTER_NAME = "endTime";

export const RECURRENCE_PERIOD = [
  {
    id: "period-none",
    label: "None",
    value: null,
  },
  {
    id: "period-daily",
    label: "Daily",
    value: "DAILY",
  },
  {
    id: "period-weekly",
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    id: "period-monthly",
    label: "Monthly",
    value: "MONTHLY",
  },
];

export const RECURRENCE_SERIES_PERIOD = [
  ...RECURRENCE_PERIOD,
  {
    id: "period-after-purchase",
    label: "After Purchase",
    value: "AFTER_PURCHASE",
  },
];

export const END_PARAMETER = [
  {
    id: "parameter-loop",
    label: "Loop",
    value: "LOOP",
  },
  {
    id: "period-series-end",
    label: "Series end",
    value: "SERIES_END",
  },
];
